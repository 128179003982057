import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { CursorClickIcon, MailOpenIcon, UsersIcon, CalculatorIcon, CashIcon, ChartBarIcon, OfficeBuildingIcon, ShareIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function renderSwitch(icon) {
    switch(icon) {
        case 'UsersIcon':
            return <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'MailOpenIcon':
            return <MailOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CursorClickIcon':
            return <CursorClickIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CalculatorIcon':
            return <CalculatorIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CashIcon':
            return <CashIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'ChartBarIcon':
            return <ChartBarIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'OfficeBuildingIcon':
            return <OfficeBuildingIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'ShareIcon':
            return <ShareIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      default:
        return '';
    }
  }

export default function PaymentStats(props) {

    const [ stats, setStatsList ] = useState([]);
    const axiosPrivat = useAxiosPrivate();
    
    const initGetStatsList = async () => {
        let isMounted = true;
        const controller = new AbortController();

        const getStatstList = async () => {
            try {
                const response = await axiosPrivat.get('/mobilepay/invoice/stats/'+props.slug, {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setStatsList(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getStatstList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
        setStatsList(stats);
        initGetStatsList();
        window.Echo.connect()
        window.Echo.channel("invoices").listen(".newInvoice", (event) => {
          if (event.update === 'success') {
            initGetStatsList();
            console.log('Invoice stats updated');
          }
      });
      return () => {
        window.Echo.disconnect()
      }
    }, [])

  return (
    <div>
        <br></br>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Betalings statestik</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                  {renderSwitch(item.icon)}                            
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              <p
                className={classNames(
                  item.changeType === 'increase' ? 'text-green-600' : 
                  item.changeType === 'decrease' ? 'text-red-600' : "",
                  'ml-2 flex items-baseline text-sm font-semibold'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                ) : 
                item.changeType === 'decrease' ? (
                  <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                ) : ""}

                <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'}</span>
                {item.change}
              </p>
              <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <Link to="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    Se alle<span className="sr-only"> {item.name} stats</span>
                  </Link>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
      <br></br>
    </div>
  )
}