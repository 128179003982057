import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from '../assets/img/Logo-whit.png';

import { axiosAuth } from '../api/axios';
import { LockClosedIcon } from '@heroicons/react/outline';
const LOGIN_URL = '/auth/sign-in';


const Login = () => {
    const { setAuth, setUserData } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [remember, setRemember] = useState(false)

    useEffect(() => {
        userRef.current.focus();
        setAuth({});
        setUserData({})
        localStorage.removeItem('aT');
        localStorage.removeItem('us-Id');
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosAuth.post(LOGIN_URL,
                JSON.stringify({email: user, password: pwd}),
            );
            //console.log(JSON.stringify(response?.data));
            const accessToken = response?.data?.token;
            const id = response?.data?.id;
            const roles = [response?.data?.roles[0].role_slug];
            localStorage.setItem('aT', accessToken);
            localStorage.setItem('us-Id', id);
            setAuth({ user, pwd, roles, accessToken, id });

            setPwd('');
            setUser('');
            navigate(from, { replace: true});
        } catch (err) {
          //console.log(err?.response)
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 422) {
                setErrMsg('Mangler brugernavn eller kode');
            } else if (err.response?.status === 401) {
                setErrMsg(err.response.data.error);
            } else {
                setErrMsg('Forkert brugernavn eller kode');
            }
        }
    }

    return (
      <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white-900">
            Init-Together Admin Dashboard
          </h2>
          <p className="mt-2 text-center text-sm">
            <a href="#" className="font-medium  text-white-600 hover:text-primary-blue">
              kontakt din administrator for at få et login
            </a>
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6 bg-white p-10 rounded-xl shadow-gray-500 shadow-md">    
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                ref={userRef}
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email adresse"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
              />
            </div>
            <p ref={errRef} className={errMsg ? "errmsg text-red-500 text-left" : "offscreen"} aria-live="assertive">{errMsg}</p> 
          </div>
          

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={(e) => setRemember(e.target.checked)}
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Husk mig
              </label>
            </div>

            <div className="text-sm">
              <a href="/resendPassword" className="font-medium text-indigo-600 hover:text-indigo-500">
                Glemt koden?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
              </span>
              Log ind
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
    )
  }

  export default Login;