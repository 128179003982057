import { Fragment, useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProjectsList( props ) {
  const { searchTerm } = useAuth();
  const [ activeList, setActiveList ] = useState([]);

  useEffect(() => {
    setActiveList(props.projectList);
  }, [props.projectList]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <br></br>
          <h1 className="text-xl font-semibold text-gray-900">Projekter</h1>

        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={props.openPopup}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Tilføj projekt
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th></th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text text-sm font-semibold text-gray-900">
                      Beskrivelse
                    </th>
                    <th scope="col" className="px-3 py-3.5 text text-sm font-semibold text-gray-900">
                      Pris (kr)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text text-sm font-semibold text-gray-900">
                      indsamlet (kr)
                    </th>
                    <th scope="col" className="px-3 py-3.5 text text-sm font-semibold text-gray-900">
                      Rækkefølge
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {activeList.sort((a, b) => a.event_start_date < b.event_start_date ? 1 : -1).map((projectList) => (
                    <Fragment key={projectList.name}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={7}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {projectList.name}
                          <span className={projectList.type === 'event_group' ? 'inline-flex rounded-full ml-4 bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800' : ''}>
                        {projectList.type === 'event_group' ? 'Eventgruppe' : ''}
                        </span>

                        </th>
                      </tr>
                      {projectList.projects.map((project, personIdx) => (
                        <tr
                          key={project.id}
                          className={classNames(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td>
                          <img
                            className="inline-block h-9 w-9 ml-4 mr-5"
                            src={project.img + "/36x36"}
                            alt={project.name}
                          />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {project.name}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500 max-w-sm">{project.description}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(project.price)} kr</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(project.donations)} kr</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{project.sort_order}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              type="button"
                              onClick={() => props.openPopupUpdate(project.id)}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}