import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [userData, setUserData] = useState({});
    const [searchTerm, setSearchTerm] = useState();

    return (
        <AuthContext.Provider value={{ auth, setAuth, userData, setUserData, searchTerm, setSearchTerm}}>
            {children}    
        </AuthContext.Provider>
    )
}

export default AuthContext;