export default function StatusStyles() {
  const statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
    udløbet: 'bg-indigo-100 text-indigo-800',
    aktiv: 'bg-green-100 text-green-800',
    deaktiveret: 'bg-red-100 text-red-800',
  }
  return statusStyles
}