import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";
import IsAuth from '../../utillityComponents/IsAuth';
import PacmanLoader from "react-spinners/PacmanLoader";


const locationMethods = [
  { id: 'dansk', title: 'Dansk', value: 'da' },
  { id: 'engelsk', title: 'Engelsk', value: 'en' },
]

const genderMethods = [
  { id: 'Mand', title: 'Mand', value: 'm' },
  { id: 'Kvinde', title: 'Kvinde', value: 'f' },
  { id: 'Ingen', title: 'Ingen', value: '' },
]

export default function CreateParticipants(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)


  const [excelFile, setExcelFile] = useState(null);
  const [resultExcelFile, setResultExcelFile] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [location, setLocation] = useState();
  const [gender, setGender] = useState();
  const [startnr, setStartnr] = useState(null);
  const [typeEnum, setTypeEnum] = useState(null);
  const [typeEnumDefault, setTypeEnumDefault] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
        getTypeEnums();
        setTypeEnum(null);
        setError(null);
        setLoading(false);
    }
  }, [props.show])

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });

  const handleChange = (file) => {
    setExcelFile(file[0])
  }

  const resultHandleChange = (file) => {
    setResultExcelFile(file[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    if (excelFile !== null) {
      formData.append("participant_file", excelFile);
    }

    if (resultExcelFile !== null) {
      formData.append("result_file", resultExcelFile);
    }
  
    if (firstName !== null) {
      formData.append("first_name", firstName);
    }
    if (lastName !== null) {
      formData.append("last_name", lastName);
    }
    if (email !== null) {
      formData.append("email", email);
    }
    if (startnr !== null) {
      formData.append("startnr", startnr);
    }
    if (typeEnum !== null) {
      formData.append("type_enum", typeEnum);
    }
   
    formData.append("locale", location);

    if (gender) {
      formData.append("gender", gender);
    }
      
    const saveEdit = async () => {
      setError(null);
      setLoading(true);
       try {
            const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/event/get/'+props.eventSlug+'/participant/create', formData
            );

            isMounted  && setOpen(false)
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError('Der skete en fejl, prøv igen. Bliver fejlen ved med at opstå, så kontakt info@init-together.dk og skriv tidspunktet for fejlen, så vi kan finden den i vores log');
        }  
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
        setLoading(false);
    }
  }

  const getTypeEnums = async () => {
    try {
         const response = await uploadAxios.get(process.env.REACT_APP_API_BASEURL + '/participant/'+props.eventSlug+'/type_enum');
         setTypeEnumDefault(response.data);
     } catch (err) {
         console.error(err);
     }  
 }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Opret Deltager</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Fornavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) => setFirstName(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Efternavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                onChange={(e) => setLastName(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="startnr" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Startnummer
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="startnr"
                                id="startnr"
                                onChange={(e) => setStartnr(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Sprog
                            </label>
                            <fieldset className="mt-4">
                              <div className="0">
                                {locationMethods.map((locationMethod) => (
                                  <div key={locationMethod.id} className="flex items-center">
                                    <input
                                      id={locationMethod.id}
                                      name="location-method"
                                      type="radio"
                                      value={locationMethod.value}
                                      onChange={(e) => setLocation(e.target.value)}
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                    <label htmlFor={locationMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                                      {locationMethod.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Køn
                            </label>
                            <fieldset className="mt-4">
                              <div className="0">
                                {genderMethods.map((genderMethod) => (
                                  <div key={genderMethod.id} className="flex items-center">
                                    <input
                                      id={genderMethod.id}
                                      name="gender-method"
                                      type="radio"
                                      value={genderMethod.value}
                                      onChange={(e) => setGender(e.target.value)}
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                    <label htmlFor={genderMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                                      {genderMethod.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                        </div>

                        {IsAuth(['Admin', 'Ngo']) ?
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="type_enum" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Vælg gruppe
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                              id="type_enum"
                              name="type_enum"
                              onChange={(e) => setTypeEnum(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue="Ingen gruppe"
                            >
                              <option key="Ingen Gruppe" value={null}>Ingen Gruppe</option>
                              {typeEnumDefault.map((typeEnumDefault) => (
                                <option key={typeEnumDefault.type_enum} value={typeEnumDefault.type_enum}>{typeEnumDefault.type_enum}</option>
                              ))}
                          </select>
                            </div>
                        </div>
                        : ''}

                        {IsAuth(['Admin', 'Ngo']) ?
                        <>
                          <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                             <p className="text-sm text-center font-small"> Upload excel med deltagerinfo. Hvis denne funktion bruges skal ingen andre felter udfyldes. Funktionen kan kun bruges på et event hvor der endnu ikke er oprettet nogle deltagere. 
                              <br></br>
                             <br></br> 
                             Kolonnerækkefølgen skal være som følgende (kun rådata uden overskrifter): <br></br>Startnr - Fornavn - Efternavn - Email - Køn (m/f).
                              <br></br>
                              <br></br>
                             Det er kun startnr og fornavn der er påkrævet for at uploade, de andre felter kan efterlades blanke.
                              <br></br>
                              <br></br>
                              Dog er det vigtig at hvis man kun eks har startnr, fornavn og email så efterlader efternavn kolonnen blank, og ikke fjerne den/rykker felterne sammen.
                              <br></br>
                              <br></br>
                              Man kan ikke overskrive andre løbere/startnumre.
                              <br></br>
                              <br></br>
                              VIGTIGT! Når en liste er blevet uploadet, bliver der med det samme sendt en mail til alle deltagere med deres unikke links til at uploade billede og dele på sociale medier (hvis der er en email på deltageren).
                             </p>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                              <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                              Upload excel
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <input  
                                  name="file"
                                  id="file"
                                  type="file"
                                  onChange={(e) => handleChange(e.target.files)}
                                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  />
                              </div>
                          </div>
                          </> 
                          : null}

                        {IsAuth(['Admin']) ?
                        <>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                              <label htmlFor="resultFile" className="block text-sm font-medium text-gray-700">
                              Resultat upload (må kun bruges af Amby)
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <input  
                                  name="resultFile"
                                  id="resultFile"
                                  type="file"
                                  onChange={(e) => resultHandleChange(e.target.files)}
                                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  />
                              </div>
                          </div>
                          </> 
                          : null}

                          <>
                          {error ? 
                          
                          <div>
                            <p className="text-md font-medium text-red-600"> {error} </p>
                          </div> 

                          : null}
                          </>

                        </div>
                    </div>

                    
                    
                    </div>
            
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    {!loading ? 
                    <>
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Opret
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                        </>
                        :
                        <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                      }
                    
                    </div>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}