import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import CreateProject from "./CreateProject";
import ProjectsList from "./ProjectsList";
import UpdateProject from "./UpdateProject";

export default function ProjectsHome() {
  const [ popup, setPopup ] = useState(false)
  const [ popupUpdate, setPopupUpdate ] = useState(false)
  const [ projectList, setProjectList ] = useState([])
  const [ projectId, setProjectId ] = useState();
  const axiosPrivat = useAxiosPrivate();

  const openPopup = async () => {
    setPopup(true)
    setTimeout(() => {
        setPopup(false)
      }, 1000);
  }

  const openPopupUpdate = async (projectId) => {
    setProjectId(projectId)
    setPopupUpdate(true)
    setTimeout(() => {
      setPopupUpdate(false)
      }, 1000);
  }

  const initGetProjectList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getProjectList = async () => {
        try {
            const response = await axiosPrivat.get('/project/all', {
                signal: controller.signal
            });
            //console.log(response.data);
            isMounted && setProjectList(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    getProjectList();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  useEffect(() => {
      initGetProjectList();
      window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
            if (event.update === 'success') {
                initGetProjectList();
                console.log('Project updated');
            }
        });
        return () => {
            window.Echo.disconnect()
        }
  }, [])

  return (
    <>
    <ComponentHeadline icon="CollectionIcon" pagetitle="Projekter" />
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <ProjectsList projectList={projectList} openPopup={() => openPopup()} openPopupUpdate={(projectId) => openPopupUpdate(projectId)} />
    </div>
    <CreateProject projectList={projectList} show={popup} />
    <UpdateProject projectList={projectList} show={popupUpdate} projectId={projectId}/>
    </>
  )
}