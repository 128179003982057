import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import Terms from "./Terms";

export default function PrivateTerms() {
  return (
    <>
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <Terms />
    </div>
    </>
  )
}