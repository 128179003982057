import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import PacmanLoader from "react-spinners/PacmanLoader";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function DeleteDistance(props) {
  const [open, setOpen] = useState(props.show)
  const [distanceId, setDistanceId] = useState(10)
  const cancelButtonRef = useRef(null)
  const axiosPrivat = useAxiosPrivate();

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
        setDistanceId(props.id)
    }
    return () => {
    }
    // eslint-disable-next-line
  }, [props.show])

  const deleteParticipant = async ( distanceId ) => {
    try {
      setLoading(true)
      await axiosPrivat.delete(process.env.REACT_APP_API_BASEURL + '/distance/delete/'+distanceId);
      console.log('delete event')
      setOpen(false)
    } catch (err) {
        setLoading(false)
        console.error(err);
    }
    setLoading(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 text-center font-medium text-gray-900">Sikker på at du vil slette denne indberetning?</h3>
                        </div>
                    </div>

                    </div>
                    
                    </div>
                    <>
                    {!loading ?
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        onClick= {() => deleteParticipant(props.id)}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Slet
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {setOpen(false)}}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    :
                        <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                        }
                        </>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}