import moment from "moment";

export default function DistanceList( props ) {

  return (
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                      Startnr
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                      Deltager
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Km
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Runder
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Oprettet
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Oprettet af bruger
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    </th>               
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.list.map((historik, personIdx) => (
                    <tr key={personIdx} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {historik.participant_startnr}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.participant_name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.total_distance_meter ? Math.round((historik.total_distance_meter / 1000) * 100 ) / 100 : 0}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.track_rounds}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(historik.created_at).format("dddd, DD MMM HH:mm YYYY")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.user_name}</td>
                      <td className="max-w-[40px] px-3 py-4 text-sm text-gray-500">
                      <button
                              key={historik.id + "_edit"}
                              type="button"
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => props.openDeletePopup(historik.id)}
                              >
                              Slet
                            </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}