import { Outlet } from "react-router-dom";
import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import PaymentEventList from "./PaymentEventList";

export default function PaymentHome() {
  return (
    <>
    <ComponentHeadline icon="CashIcon" pagetitle="Betalinger" />
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
      <Outlet />
    </div>
    </>
  )
}