import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteDistance from "./DeleteDistance";
import DistanceList from "./DistanceList";

export default function DistanceInit() {
  const axiosPrivat = useAxiosPrivate();
  const { event_slug } = useParams()
  const [ distanceList, setDistanceList ] = useState([])
  const [ distranceId, setDistanceId ] = useState()
  const [ deletePopup, setDeletePopup ] = useState(false)

  const deleteParticipant = async (id) => {
    setDistanceId(id)
    setDeletePopup(true)
    setTimeout(() => {
      setDeletePopup(false)
      }, 1000);
  }

  const initGetDistanceList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getDistanceList = async () => {
        try {
            const response = await axiosPrivat.get('/distance/get/'+event_slug, {
                signal: controller.signal
            });
            //console.log(response.data);
            isMounted && setDistanceList(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    getDistanceList();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  useEffect(() => {
    initGetDistanceList();
    window.Echo.connect()
      window.Echo.channel("events").listen(".newEvent", (event) => {
          if (event.update === 'success') {
            initGetDistanceList();
          }
      });
}, [])

  return (
    <>
    <DistanceList list={distanceList} openDeletePopup={(id) => deleteParticipant(id)} />
    <DeleteDistance show={deletePopup} id={distranceId} />
    </>
  )
}