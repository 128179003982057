import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import IsAuth from "../../utillityComponents/IsAuth";
import LoadingSpinner from "../../utillityComponents/LoadingSpinner";
import ModalSuccess from "../../utillityComponents/ModalSuccess";

export default function ProfileForm() {
    const axiosPrivate = useAxiosPrivate();
    const { auth, setUserData, userData } = useAuth();
    const { user_id } = useParams();
    let navigate = useNavigate();
  
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [photoFile, setPhotoFile] = useState(null);
    const [email, setEmail] = useState('');
    const [allRoles, setAllRoles] = useState([]);
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [pwError, setPwError] = useState('');
    const [modelSave, setModelSave] = useState(false);
    const [notification, setNotification] = useState('');
    const [editData, setEditData] = useState('');
    const [loaded, setLoades] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const userId = user_id? user_id : auth.id;

    const handleChange = (file) => {
        setPhotoFile(file[0])
    }

    const uploadAxios = axios.create({
      headers: {
          Authorization: 'Bearer ' + localStorage.getItem('aT'),
          'Content-Type': 'multipart/form-data',
      },
    });

    const saveComplete = async () => {
      setModelSave(true)
      setTimeout(() => {
        setModelSave(false)
      }, 2000);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isMounted = true;
        const controller = new AbortController();
        const formData = new FormData();
        
        formData.append("is_activated", isActive);

        if (firstName !== null) {
          formData.append("firstName", firstName);
        }
  
        if (lastName !== null) {
          formData.append("lastName", lastName);
        }

        if (city !== null) {
          formData.append("city", city);
        }
  
        if (email !== null) {
          formData.append("email", email);
        }
    
        if (photoFile !== null) {
          formData.append("img", photoFile);
        }

        if (password !== verifyPassword) {
            setPwError('Koderne er ikke ens')
            return;
        }

        if (password.length !== 0 && password.length < 8) {
          setPwError('Koderne er for kort - min 8 tegn')
          return;
        }

        if (password.length !== 0) {
          formData.append("password", password);
        }

        if (photoFile !== null) {
          formData.append("img", photoFile);
        }

        const saveEdit = async () => {
           try {
                const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/user/edit/' + userId, formData
                );
                const profil_img = response?.data?.user_profile_image ? process.env.REACT_APP_CLUDFLARE_IMG_BASE_URL + response?.data?.user_profile_image : process.env.REACT_APP_CLUDFLARE_DEFAULT_IMG_URL;
                const roles = response?.data?.roles;
                const name = response?.data?.user_display_name;
                const id = response?.data?.id;
                const email = response?.data?.user_email;
                const first_name = response?.data?.user_first_name;
                const last_name = response?.data?.user_last_name;
                const city = response?.data?.user_city;
                isMounted  && saveComplete()
                if (userId == auth.id) {
                  setUserData({ profil_img, email, id, roles, name, first_name, last_name, city })
                }
                setEditData({ profil_img, email, id, roles, name, first_name, last_name, city })
                setPwError('') 
            } catch (err) {
                console.error(err);
                console.log(photoFile);
            }  
        }

        saveEdit();

        return () => {
            isMounted = false;
            controller.abort();
            setPwError('')
        }
    }

    const getUserData = async () => {
      let isMounted = true;
      const controller = new AbortController();

      try {
          const response = await axiosPrivate.get('/user/get/' + userId, {
              signal: controller.signal
          });
          const profil_img = response?.data?.user_profile_image ? process.env.REACT_APP_CLUDFLARE_IMG_BASE_URL + response?.data?.user_profile_image : process.env.REACT_APP_CLUDFLARE_DEFAULT_IMG_URL;
          const roles = response?.data?.roles;
          const name = response?.data?.user_display_name;
          const id = response?.data?.id;
          const email = response?.data?.user_email;
          const first_name = response?.data?.user_first_name;
          const last_name = response?.data?.user_last_name;
          const city = response?.data?.user_city;
          const isActivated = response?.data?.is_user_activated;
          isMounted && setEditData({ profil_img, email, id, roles, name, first_name, last_name, city })
          setIsActive(isActivated)
          setLoades(true)
          setShowCheckbox(true)
      } catch (err) {
          //navigate('/page_not_found')
         console.log(err?.response)
      }               

      return () => {
          isMounted = false;
          controller.abort();
      }
    }

    const setRoles = (e) => {
      console.log(e);
    } 

    useEffect(() => {
      getUserData()
    },[])

    useEffect(() => {
      setFirstName(editData.first_name)
      setLastName(editData.last_name)
      setCity(editData.city)
      setEmail(editData.email)
    },[editData.first_name, editData.last_name, editData.city, editData.email, editData.isActivated])

    /* window.Echo.channel("User").listen(".Notifications", (event) => {
      setNotification(event.notification);
      console.log(event);
    }); */

    return (
      <>
      {loaded? <>
      <ModalSuccess show={modelSave}/> 
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
      <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
  
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Information om {editData.name}</h3>
              <p className="mt-1 max-w-2xl text-sm text-red-500">{notification}</p>
            </div>
  
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Fornavn
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName ?? ''}
                    autoComplete="given-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Efternavn
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName ?? ''}
                    autoComplete="family-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Email address
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email ?? ''}
                    autoComplete="email"
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Country
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>Denmark</option>
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  By
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    onChange={(e) => setCity(e.target.value)}
                    value={city ?? ''}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>


              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                  Billede
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                    <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                    <img
                      className="inline-block h-12 w-12 rounded-full"
                      src={editData.profil_img + "/48x48"}
                      alt={editData.name}
                    />
                    </span>
                    <input  
                      name="image"
                      id="image"
                      type="file"
                      onChange={(e) => handleChange(e.target.files)}
                      className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    />
                  </div>
                </div>
              </div>
  
              
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Kode
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="verify_password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Bekræft kode
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="password"
                    name="verify_password"
                    id="verify_password"
                    onChange={(e) => setVerifyPassword(e.target.value)}
                    value={verifyPassword}
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <p className="text-red-600">
                  {pwError}
              </p>
              
  
            </div>
          </div>

        {IsAuth(['Admin', 'Event_admin']) ?
          <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        
              <div className="text-sm">
                <label htmlFor="changeActive" className="font-medium text-gray-700">
                    Aktiv bruger
                </label>
                <span id="changeActive-description" className="text-gray-500">
                  <span className="sr-only">Aktiv bruger</span>
                </span>
              </div>
              <>
              { showCheckbox ? 
              <div className="center center h-5">
                <input
                  id="changeActive"
                  aria-describedby="changeActive-description"
                  name="changeActive"
                  type="checkbox"
                  defaultChecked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
              </div>
              : ""}
              </>
          </div>
        : ""}

         
        
        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid md:grid-cols-3 sm:grid-cols-1 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <fieldset className="sm:col-start-1 md:col-start-2">
                <legend className="text-lg font-medium text-gray-900">Roller</legend>
                <div className="mt-4">
                  {allRoles.map((role, roleIdx) => (
                    <div key={roleIdx} className="relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor={`role-${role}`} className="font-medium text-gray-700 select-none">
                          {role}
                        </label>
                      </div>
                      <div className="ml-3 flex items-center h-5">
                        <input
                          id={`role-${role}`}
                          name={`role-${role}`}
                          value={role}
                          onChange={(e) => {
                            setRoles(e.target.checked)
                          }}
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          checked={editData?.roles?.find(roles => role?.includes(roles))
                            ? true
                            : false}
                        />
                      </div>
                    </div>
                  ))}
                </div>
            </fieldset>
          </div>
        </div>
  
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      </div>
      </>
      : <LoadingSpinner />}
    </>
    )
  }