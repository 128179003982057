import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";
import IsAuth from '../../utillityComponents/IsAuth';
import PacmanLoader from "react-spinners/PacmanLoader";

export default function UpdateParticipant(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)

  const [photoFile, setPhotoFile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [startnr, setStartnr] = useState('');
  const [participantImg, setParticipantImg] = useState('');
  const [canChangeImage, setCanChangeImage] = useState(0);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendParticipantMail, setSendParticipantMail] = useState(null);
  const [meta1, setMeta1] = useState(null);
  const [meta2, setMeta2] = useState(null);
  const [meta3, setMeta3] = useState(null);
  const [meta4, setMeta4] = useState(null);
  const [typeEnum, setTypeEnum] = useState(null);
  const [typeEnumName, setTypeEnumName] = useState(null);
  const [typeEnumDefault, setTypeEnumDefault] = useState([]);
  const [excludeFfromStats, setExcludeFfromStats] = useState(null);

  useEffect(() => {
    if (props.participantSlug !== undefined) {
      getTypeEnums()
      getParticipant( props.participantSlug )
    }
    if (props.show) {
        setOpen(props.show);
    }
    return () => {
      setCanChangeImage(0)
      setShowCheckbox(false)
      setSendParticipantMail(null)
      setMeta1(null)
      setMeta2(null)
      setMeta3(null)
      setMeta4(null)
      setTypeEnum(null)
      setTypeEnumDefault([])
      setTypeEnumName(null)
      setExcludeFfromStats(null)
    }
    // eslint-disable-next-line
  }, [props.show])

  const uploadAxiosClean = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'application/json',
    },
  });

  const getParticipant = async ( participantSlug ) => {
    try {
      const response = await uploadAxiosClean.get(process.env.REACT_APP_API_BASEURL + '/participant/get/'+participantSlug);
      setFirstName(response.data.first_name)
      setLastName(response.data.last_name)
      setEmail(response.data.email)
      setStartnr(response.data.startnr)
      setParticipantImg(response.data.img)
      setCanChangeImage(response.data.can_edit_image)
      setMeta1(response.data.group_1)
      setMeta2(response.data.group_2)
      setMeta3(response.data.group_3)
      setMeta4(response.data.group_4)
      setTypeEnumName(response.data.type_enum)
      setExcludeFfromStats(response.data.is_excluded_from_stats)
      setShowCheckbox(true)
    } catch (err) {
        console.error(err);
    }
  }

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });

  const handleChange = (file) => {
    setPhotoFile(file[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("startnr", startnr);
    formData.append("can_edit_image", canChangeImage);
  
    if (photoFile !== null){
      formData.append("img", photoFile);
    }

    if (email !== null) {
      formData.append("email", email);
    }

    if (sendParticipantMail !== null) {
      formData.append("send_mail", sendParticipantMail);
    }

    if (meta1 !== null) {
      formData.append("group_1", meta1);
    }

    if (meta2 !== null) {
      formData.append("group_2", meta2);
    }

    if (meta3 !== null) {
      formData.append("group_3", meta3);
    }

    if (meta4 !== null) {
      formData.append("group_4", meta4);
    }

    if (typeEnum !== null) {
      formData.append("type_enum", typeEnum);
    }

    if (excludeFfromStats !== null) {
      formData.append("is_excluded_from_stats", excludeFfromStats);
    }

    const saveEdit = async () => {
       try {
            setLoading(true)
            await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/participant/update/'+props.participantSlug, formData
            );

            isMounted  && setOpen(false)
        } catch (err) {
            setLoading(false)
            console.error(err);
            console.log(photoFile);
        } 
        setLoading(false) 
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  const getTypeEnums = async () => {
    try {
         const response = await uploadAxios.get(process.env.REACT_APP_API_BASEURL + '/participant/'+props.eventSlug+'/type_enum');
         setTypeEnumDefault(response.data);
     } catch (err) {
         console.error(err);
     }  
 }

  const deleteParticipant = async ( participantSlug ) => {
    try {
      setLoading(true)
      await uploadAxios.delete(process.env.REACT_APP_API_BASEURL + '/participant/delete/'+participantSlug);
      console.log('delete event')
      setOpen(false)
    } catch (err) {
        setLoading(false)
        console.error(err);
    }
    setLoading(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Rediger Deltager - {firstName}</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Fornavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Efternavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="startnr" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Startnummer
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="startnr"
                                id="startnr"
                                onChange={(e) => setStartnr(e.target.value)}
                                value={startnr}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="meta1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Meta 1
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="meta1"
                                id="meta1"
                                onChange={(e) => setMeta1(e.target.value)}
                                value={meta1}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="meta2" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Meta 2
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="meta2"
                                id="meta2"
                                onChange={(e) => setMeta2(e.target.value)}
                                value={meta2}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="meta3" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Meta 3
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="meta3"
                                id="meta3"
                                onChange={(e) => setMeta3(e.target.value)}
                                value={meta3}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="meta4" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Meta 4
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="meta4"
                                id="meta4"
                                onChange={(e) => setMeta4(e.target.value)}
                                value={meta4}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            />
                            </div>
                        </div>

                        {IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                          <p className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>Nuværende gruppe: </p>
                            <p className='mt-1 sm:col-span-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>{typeEnumName}</p>
                            <label htmlFor="type_enum" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Vælg gruppe
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                              id="type_enum"
                              name="type_enum"
                              onChange={(e) => setTypeEnum(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue="Ingen gruppe"
                            >
                              <option key="Ingen Gruppe" value={null}>Ingen Gruppe</option>
                              {typeEnumDefault.map((typeEnumDefault) => (
                                <option key={typeEnumDefault.type_enum} value={typeEnumDefault.type_enum}>{typeEnumDefault.type_enum}</option>
                              ))}
                          </select>
                            </div>
                        </div>
                        : ''}

                        <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      
                            <div className="text-sm">
                              <label htmlFor="exclude_from_stats" className="font-medium text-gray-700">
                                  Fjern km fra statistik
                              </label>
                              <span id="exclude_from_stats-description" className="text-gray-500">
                                <span className="sr-only">Fjern km fra statistik</span>
                              </span>
                            </div>
                            <>
                            { showCheckbox ? 
                            <div className="center center h-5">
                              <input
                                id="exclude_from_stats"
                                aria-describedby="exclude_from_stats-description"
                                name="exclude_from_stats"
                                type="checkbox"
                                defaultChecked={excludeFfromStats}
                                onChange={(e) => setExcludeFfromStats(e.target.checked)}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                            : ""}
                            </>
                        </div>

                        <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      
                            <div className="text-sm">
                              <label htmlFor="changeImg" className="font-medium text-gray-700">
                                  Må ændre billede
                              </label>
                              <span id="changeImg-description" className="text-gray-500">
                                <span className="sr-only">Må ændre billede</span>
                              </span>
                            </div>
                            <>
                            { showCheckbox ? 
                            <div className="center center h-5">
                              <input
                                id="changeImg"
                                aria-describedby="changeImg-description"
                                name="changeImg"
                                type="checkbox"
                                defaultChecked={canChangeImage}
                                onChange={(e) => setCanChangeImage(e.target.checked)}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                            : ""}
                            </>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                            Billede
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-3">
                                <img
                                className="inline-block h-9 w-9 rounded-full mr-4"
                                src={participantImg + "/36x36"}
                                alt={firstName + " " + lastName}
                              />
                                <input  
                                name="image"
                                id="image"
                                type="file"
                                onChange={(e) => handleChange(e.target.files)}
                                className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                />
                            </div>
                        </div>

                        <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      
                            <div className="text-sm">
                              <label htmlFor="participantMail" className="font-medium text-gray-700">
                                  Genudsend mail til deltager
                              </label>
                              <span id="participantMail-description" className="text-gray-500">
                                <span className="sr-only">Genudsend mail til deltager</span>
                              </span>
                            </div>
                            <>
                            { showCheckbox ? 
                            <div className="center center h-5">
                              <input
                                id="participantMail"
                                aria-describedby="participantMail-description"
                                name="participantMail"
                                type="checkbox"
                                defaultChecked={sendParticipantMail}
                                onChange={(e) => setSendParticipantMail(e.target.checked)}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                            : ""}
                            </>
                        </div>

                        {!loading && IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <button
                            type="button"
                            className="w-50 flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                            onClick= {() => deleteParticipant(props.participantSlug)}
                            >
                            Slet Deltager
                            </button>
                        </div>
                        : ""}

                        
                        </div>
                    </div>

                    </div>
                    
                    </div>
                    <>
                    {!loading ?
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Gem
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {setOpen(false)}}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    :
                        <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                        }
                        </>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}