import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const notificationMethods = [
  { id: 'mobilepay', title: 'Mobilepay', value: 'mobilepay' },
  { id: 'transfer', title: 'Bankoverførsel', value: 'transfer' },
]

export default function RegisterRound(props) {
  const axiosPrivat = useAxiosPrivate();
  const [open, setOpen] = useState(props.show)
  const [ name, setName ] = useState()
  const [ startnr, setStartnr ] = useState()
  const [ slug, setSlug ] = useState()
  const [ routeId, setRouteId ] = useState()
  const [ routeTitle, setRouteTitle ] = useState()
  const [ routeValue, setRouteValue ] = useState()
  const [ error, setError ] = useState('')
  const [ mounted, setMounted ] = useState(false)
  const [ rounds, setRounds ] = useState(null)

  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
        setName(props.name)
        setStartnr(props.startnr)
        setSlug(props.slug)
        setRouteId(props.routeId)
        setRouteTitle(props.routeTitle)
        setRouteValue(props.routeValue)
        setError('')
        setMounted(false)
        setRounds(1)
    } 
    // eslint-disable-next-line
  }, [props.show])

  useEffect(() => {
    setRouteValue(props.routeValue * rounds)
    // eslint-disable-next-line
  }, [rounds])

  const handleSubmit = async (e) => {
      e.preventDefault();
      let isMounted = true;
      setMounted(true)
      const controller = new AbortController();

      try {
        const response = await axiosPrivat.post('/event/register/'+slug+'/'+startnr, {
            signal: controller.signal,
            name: name,
            startnr: startnr,
            slug: slug,
            route_id: routeId,
            route_title: routeTitle,
            route_value: routeValue,
            rounds: rounds
        });
        setError('')
        setOpen(false)
        isMounted = false;
        //console.log(response.data);
      } catch (err) {
          console.error(err);
          setError('Der er sket en fejl. Prøv igen.')
          setMounted(false)
      }

      return () => {
          setMounted(false)
          this.isMounted = false;
          controller.abort();
      }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h2 className='text-lg text-center leading-6 font-medium text-red-900 mb-10'>{error}</h2>
                        <h3 className="text-lg text-center leading-6 ">Registrer omgang</h3>
                        <br/>
                        <p className='text-center mt-5 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 sm:border-t sm:border-gray-200'>Løber: {startnr} - {name}</p>
                        <p className='text-center mt-5 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>Rute: {routeTitle}</p>
                        <p className='text-center mt-5 text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>Distance: {routeValue/1000} km</p>
                        
                        <div className="sm:grid w-[300px] m-auto text-center sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="rounds" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Omgange: 
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="rounds"
                              name="rounds"
                              type="number"
                              onChange={(e) => setRounds(e.target.value)}
                              value={rounds}
                              required
                              className="relative block w-[100px] appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="space-y-6 sm:space-y-5">

                        </div>
                    </div>

                    
                    
                    </div>
                    <>
                    {mounted ? <p className='text-center mt-5'>Gemmer</p> :
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Bekræft
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        >
                        Fortryd
                        </button>
                      </div>
                      }
                      </>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}