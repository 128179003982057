import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

export default function HardwareList(props) {
    const [ displayList, setDisplayList ] = useState([])
    const axiosPrivat = useAxiosPrivate();

    const initGetDisplayList = async () => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getDisplayList = async () => {
            try {
                const response = await axiosPrivat.get('/hardware/all', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setDisplayList(response.data);
            } catch (err) {
                console.error(err);
            }
        }
    
        getDisplayList();
    
        return () => {
            isMounted = false;
            controller.abort();
        }
    }
    

    useEffect(() => {
        initGetDisplayList();
        window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
            if (event.update === 'success') {
                initGetDisplayList();
            }
        });
        return () => {
            window.Echo.disconnect()
            setDisplayList([])
        }
    }, [])

  return (
    <>
    <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
    </h2>
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-conter text-sm font-semibold text-gray-900 sm:pl-6">
                      Event
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-conter text-sm font-semibold text-gray-900 sm:pl-6">
                      Linjer
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-conter text-sm font-semibold text-gray-900 sm:pl-6">
                      Display tekst
                    </th>
                    <th className="py-3.5 pl-4 pr-3 text-conter text-sm font-semibold text-gray-900 sm:pl-6">
                      Skriftstørrelse
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {displayList.sort((a, b) => a.name > b.name ? 1 : -1).map((display) => (
                    <tr key={display.id}>
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{display.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">{display.event_slug}</div>
                      </td>
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">{display.lines}</div>
                      </td>
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900"><span style={{ color: "rgb("+display.stats.color_line_one+")"}}>{display.stats.title_line_one}</span><br></br> <span style={{ color: "rgb("+display.stats.color_line_two+")"}}>{display.stats.title_line_two}</span><br></br> <span style={{ color: "rgb("+display.stats.color_line_three+")"}}>{display.stats.title_line_three}</span><br></br> <span style={{ color: "rgb("+display.stats.color_line_four+")"}}>{display.stats.title_line_four}</span></div>
                      </td>
                      <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">Font-size: {display.stats.font_line_one}<br></br> Font-size: {display.stats.font_line_two} <br></br> Font-size: {display.stats.font_line_three} <br></br> Font-size: {display.stats.font_line_four}</div>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            key={display.id + "_edit"}
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick= {() => props.openPopupUpdateHardware(display.slug)}
                            >
                            Rediger
                          </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}