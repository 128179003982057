import useAuth from "./useAuth";
import { axiosAuth } from '../api/axios';


const useRefreshToken = () => {
    const { auth, setAuth, setUserData } = useAuth();

    const refresh = async () => {
        try {
            const response = await axiosAuth.get('/user/current',
                {
                    aT: localStorage.getItem('aT')
                }
            );
            //console.log(JSON.stringify(response?.data));
            const accessToken = localStorage.getItem('aT');
            const id = response?.data?.id;
            const roles = [response?.data?.roles[0].role_slug];
            localStorage.setItem('aT', accessToken);
            setAuth({ roles, accessToken, id });
        } catch (err) {
          //console.log(err?.response)
            setAuth({});
            setUserData({});
            localStorage.removeItem('aT');
        } finally {
            return;
        }                   
    }
    

  return (refresh)
}

export default useRefreshToken