import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../404';
import bg_img from '../../assets/img/login-bg.png';
import PacmanLoader from "react-spinners/PacmanLoader";
import { FadeLoader } from 'react-spinners';

export default function ParticipantUpload() {
    const { event_slug } = useParams();
    const { participant_slug } = useParams();
    const [participant, setParticipant] = useState();
    const [org, setOrg] = useState();
    const [img, setImg] = useState();
    const [isMounted, setIsMounted] = useState(false);
    const [isFound, setIsFound] = useState(false);
    const [photoFile, setPhotoFile] = useState(null);
    const [thanks, setThanks] = useState(false);
    const [errorr, setErrorr] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasImg, setHasImg] = useState(false);
    
    const getParticipant = async () => {
        try {
          axios.get(process.env.REACT_APP_API_BASEUR_PUBLIC + `/event/get/`+event_slug+`/participant/image/`+participant_slug)
                .then(res => {
                    const persons = res.data;
                    setParticipant(persons.name);
                    setImg(persons.img);
                    setOrg(persons.org);
                    setIsMounted(true);
                    setIsFound(true);
                })
                .catch(err => {
                    setIsFound(false);
                    setIsMounted(true);
                })
                
          } catch (err) {
              setIsFound(false);
              setIsMounted(true);

          }
        
     }

    const handleChange = (file) => {
        setPhotoFile(file[0])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorr("");
        const controller = new AbortController();
        const formData = new FormData();
        formData.append("img", photoFile);
        
        const saveEdit = async () => {
           try {
                setLoading(true);
                const result = await axios.post(process.env.REACT_APP_API_BASEUR_PUBLIC + `/event/get/`+event_slug+`/participant/`+participant_slug+`/img/add`, formData)
                setParticipant(result.data.name);
                setImg(result.data.img);
                setThanks(true);

                const url = 'https://app.init-together.dk/'+result.data.org_slug+'/'+result.data.event_slug+'/deltagere/' + result.data.slug;
                window.location.replace(url);
                
            } catch (err) {
                setLoading(false);
                setErrorr('Det er sket en fejl, prøv igen');
                console.error(err);
            }  
            setLoading(false);
        }

        saveEdit();

        return () => {
            controller.abort();
        }
    }

     useEffect(() => { 
        getParticipant();
        // eslint-disable-next-line
     }, [])
     
     useEffect(() => { 
      if (photoFile !== null) {
          setHasImg(true);
      } else {
          setHasImg(false);
      }
   }, [photoFile])

  return (
    <>
    {isMounted ?
     isFound ?
      <div>
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src={bg_img}
              alt="Init-together backgroud"
            />
            <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl text-left">Velkommen til {participant}</h1>
            <p className="mt-6 max-w-3xl text-xl text-gray-300 text-left">
              Tak fordi du har valgt at deltage som løber for {org}. <br></br>
              Du kan her uploade det billede der vises på din profil hos <a href="https://app.init-together.dk" target="_blank" rel="noreferrer">app.init-together.dk</a>
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Upload billede
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-2 lg:gap-y-0 lg:gap-x-8">
    
              <div className="flex flex-col bg-white rounded-2xl shadow-xl">
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="top-0 p-2 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2 text-left mt-5">
                    <img
                        className="inline-block h-28 w-28 rounded-full"
                        src={img + "/112x112"}
                        alt={participant}
                      />
                  </div>
                  <h3 className="text-xl font-medium text-red-900">{errorr}</h3><br></br>
                  {thanks ? <h3 className="text-xl font-medium text-green-900">Tak, dit billede er nu uploaded</h3> : <h3 className="text-xl font-medium text-gray-900">Upload et billede</h3>}
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2 mr-5 ml-5 mb-5">
                  <form onSubmit={handleSubmit} className="space-y-6">

                          <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start sm:border-t sm:border-b sm:border-gray-200 sm:pt-5 sm:pb-5">
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <input  
                                  name="file"
                                  id="file"
                                  type="file"
                                  onChange={(e) => handleChange(e.target.files)}
                                  className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  />
                              </div>
                          </div>
                          <>
                          {hasImg ? 
                            !loading ?
                              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
                              <button
                              type="submit"
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                              >
                              Upload
                              </button>
                              </div>
                              :
                              <PacmanLoader
                              color="#26103c"
                              cssOverride={{margin: "20px auto"}}
                              loading
                              margin={0}
                              size={24}
                              speedMultiplier={1}
                              />
                            :''
                            }
                            </>
                  
                  </form>
                  </div>
              </div>
          </div>
        </section>
      </div>
      : <PageNotFound />
    :  <FadeLoader
          color="#ffffff"
          cssOverride={{margin: "45vh auto",}}
          loading
          margin={10}
          size={24}
          speedMultiplier={1}
          />
    }
      </>
  )
}