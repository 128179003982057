import ComponentHeadline from '../../utillityComponents/ComponentHeadline';
import HistorikList from './HistorikList';

export default function HistorikHome() {
    
  return (
    <>
    <ComponentHeadline icon="UserGroupIcon" pagetitle="Historik" />
    <HistorikList />
    </>
  )
}