import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ParticipantList from "./ParticipantList";
import RegisterBulk from "./RegisterBulk";
import RegisterRound from "./RegisterRound";

export default function PerticipantInit() {
  const axiosPrivat = useAxiosPrivate();
  const { event_slug } = useParams()
  const [ participantList, setParticipantList ] = useState([])
  const [ registerPopup, setRegisterPopup ] = useState(false)
  const [ registerBulkPopup, setRegisterBulkPopup ] = useState(false)
  const [ name, setName ] = useState()
  const [ startnr, setStartnr ] = useState()
  const [ slug, setSlug ] = useState()
  const [ routeId, setRouteId ] = useState()
  const [ routeTitle, setRouteTitle ] = useState()
  const [ routeValue, setRouteValue ] = useState()

  const openRegisterPopup = async (name, startnr, slug, routeId, routeTitle, routeValue) => {
    setName(name)
    setStartnr(startnr)
    setSlug(slug)
    setRouteId(routeId)
    setRouteTitle(routeTitle)
    setRouteValue(routeValue)
    setRegisterPopup(true)
    setTimeout(() => {
      setRegisterPopup(false)
      }, 1000);
  }

  const openRegisterBulkPopup = async () => {
    setRegisterBulkPopup(true)
    setTimeout(() => {
      setRegisterBulkPopup(false)
      }, 1000);
  }

  const initGetParticipantList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getParticipantList = async () => {
        try {
            const response = await axiosPrivat.get('/event/register/'+event_slug, {
                signal: controller.signal
            });
            //console.log(response.data);
            isMounted && setParticipantList(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    getParticipantList();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  useEffect(() => {
    initGetParticipantList();
    window.Echo.connect()
      window.Echo.channel("events").listen(".newEvent", (event) => {
          if (event.update === 'success') {
            initGetParticipantList();
              console.log('Participant updated');
          }
      });
}, [])

  return (
    <>
    <ParticipantList event_slug={event_slug} list={participantList} openRegisterBulkPopup={() => openRegisterBulkPopup()} openRegisterPopup={(name, startnr, slug, routeId, routeTitle, routeValue) => openRegisterPopup(name, startnr, slug, routeId, routeTitle, routeValue)} />
    <RegisterRound show={registerPopup} name={name} startnr={startnr} slug={slug} routeId={routeId} routeTitle={routeTitle} routeValue={routeValue} />
    <RegisterBulk show={registerBulkPopup} event_slug={event_slug}/>
    </>
  )
}