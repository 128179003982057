import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../404';
import bg_img from '../../assets/img/login-bg.png';
import { FadeLoader } from 'react-spinners';

export default function ResetPassword() {
    const { auth_password } = useParams();
    const [name, setName] = useState();
    const [isMounted, setIsMounted] = useState(false);
    const [isFound, setIsFound] = useState(false);
    const [thanks, setThanks] = useState(false);
    const [fail, setFail] = useState(false);
    const [pwError, setPwError] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    
    const getUser = async () => {
        try {
            axios.post(process.env.REACT_APP_API_BASEUR_PUBLIC + `/login/reset/`, {
              'reset_password': auth_password
            })
                .then(res => {
                    const persons = res.data;
                    setName(persons.name);
                    setIsMounted(true);
                    setIsFound(true);
                })
                .catch(err => {
                    setIsFound(false);
                    setIsMounted(true);
                })
          } catch (err) {
            setIsFound(false);
            setIsMounted(true);
          }
     }

     const resetPassword = async (e) => {
      e.preventDefault();

      if (password !== verifyPassword) {
        setPwError('Koderne er ikke ens')
        return;
      }
  
      if (!password.length == 0 && password.length < 8) {
        setPwError('Koderne er for kort - min 8 tegn')
        return;
      }
  
      if (password.length == 0) {
        setPwError('Indtast kode');
        return;
      }
  
      try {
          axios.post(process.env.REACT_APP_API_BASEUR_PUBLIC + `/login/reset/`, {
            'reset_password': auth_password,
            'password': password,
          })
          .then(res => {
                  const persons = res.data;
                  if (persons.success === true)
                    setThanks(true);
                  else 
                    setFail(true);

          })
        } catch (err) {
            console.error(err);
        }
   }

     useEffect(() => { 
        getUser();
        // eslint-disable-next-line
     }, [])

  return (
    <>
    {isMounted ?
      isFound ?
      <div >
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src={bg_img}
              alt="Init-together backgroud"
            />
            <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl text-left">Velkommen til {name}</h1>
            <p className="mt-6 max-w-3xl text-xl text-gray-300 text-left">
              Indtast din nye kode til Init-Together. <br></br>
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Reset Password
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-2 lg:gap-y-0 lg:gap-x-8">
    
              <div className="flex flex-col bg-white rounded-2xl shadow-xl">
                <div className="mt-5 md:mt-0 md:col-span-2 mr-5 ml-5 mb-5">
                  {thanks ? fail ? <h2 className="mt-6 max-w-3xl text-xl text-red-600">Der er sket en fejl. Prøv igen</h2> : <p className="mt-6 max-w-3xl text-lg text-green-600">Password er nulstillet, du kan nu gå til <a className="text-blue-600" href="https://admin.init-together.dk">admin.init-together.dk</a> og logge ind.</p> :
                  <form onSubmit={resetPassword} className="space-y-6">

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Kode
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  value={password ?? ''}
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  autoComplete="new-password"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="verify_password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Bekræft kode
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="password"
                                  name="verify_password"
                                  id="verify_password"
                                  onChange={(e) => setVerifyPassword(e.target.value)}
                                  value={verifyPassword ?? ''}
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  autoComplete="new-password"
                                  required
                              />
                              </div>
                          </div>
                          <p className="text-red-600">
                              {pwError}
                          </p>
                            

                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
                          <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                          >
                          Nulstil koden
                          </button>
                      </div>
                  
                  </form>
                  }
                  </div>
              </div>
          </div>
        </section>
      </div>
      : <PageNotFound />
    :<FadeLoader
    color="#ffffff"
    cssOverride={{margin: "45vh auto",}}
    loading
    margin={10}
    size={24}
    speedMultiplier={1}
    />}
    </>
  )
}