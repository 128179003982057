
import { Outlet } from 'react-router-dom';
import ComponentHeadline from '../../utillityComponents/ComponentHeadline';

export default function EventHome() {

  return (
    <>
    <ComponentHeadline icon="CalendarIcon" pagetitle="Event Liste" />
    <Outlet />
    </>
  )
}