
import IsAuth from "../../utillityComponents/IsAuth";
import AllStats from "../overview/AllStats";
import ListPagination from "./ListPagination"
import ProfileInfo from "./ProfileInfo"

export default function Home(props) {
  return (
    <>
    <ProfileInfo />
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {IsAuth(['Admin', 'Ngo', 'Org']) ?
            <AllStats />
            : ""
        }
    </div>
    <ListPagination title="Seneste login"/>
    </>
  )
}