export default function Terms() {
    return (
      <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Privatlivsoplysninger</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Hvordan håndtere vi din data?
              </p>
            </div>
          </div>
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                  <p>
                    Alle de services vi bruger, er godkendte GDPR udbydere.<br></br>
                    Det er kun Visualize-it der har adgang servere og backup.
                  </p>
                  <br></br>
                  <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Servere og backup:</h2>
                  <p>
                    <strong>Hetzner</strong><br></br>
                    Alle vores servere er hostet hos Hetzner og drives i et datacenter i Finland.
                  </p>
                  <br></br>
                  <p>
                    <strong>Backblaze</strong><br></br>
                    Vi tager daglig backup af vores database og billeder på siden. Backuppen bliver lagt på Backblaze hvor det er krypteret, og med en timelock der gør at bliver slettet efter 30 dage. Det vil dog også sige, at den ikke kan slettes før det.
                  </p>
                  <br></br>

                  <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Opbevaring af persondata:</h2>
                  <p>
                    En måned efter løbet bliver alle dine persondata omdannet til random data, (det vil sige at telefonnumre slettes og dit navn bliver ændret til et random generet navn). Det gøres for at bevare statistikken over de tidligere løb, men uden dine persondata.
                  </p>
                    <br></br>
                  <p>
                    Det betyder helt konkret at vi gemmer din data i 2 måneder, grundet den daglige backup der bliver gemt i en måned.
                  </p>
                    <br></br>
                  <p>
                    Du kan til enhver tid skrive til info@visualize-it.dk for at få overblik over hvad data vi har på dig, og få det fjernet med det samme. Dog kan data fra backuppen ikke fjernes, før den automatisk bliver slettet efter en måned. 
                  </p>
                  <br></br>
                  <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Donationer:</h2>
                  <p>Er ikke bindende og derved 100% frivilligt om man efter eventet vil betale det beløb, der opgøres ud fra deltagerens præstation.<br></br>
                    Visualize-it har intet med pengene at gøre, da de går direkte fra mobilepay ind på den godkendte NGOs bankkonto. Det samme sker ved bankoverførsel, hvor det er NGOens kontonummer. 
                  </p>
                </div>

                <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                  <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Videregiver oplysninger:</h2>
                    <p>Som udgangspunkt er det kun NGOen / Organisationen der står for eventet og Visualize-It der har adgang til din data, og den bliver hverken videregivet eller solgt til andre virksomheder.<br></br>
                    Dog bruger vi et par GDPR godkendte tjenester, der får dele af din data. 
                    </p>
                  <p>
                    <strong>Postmark</strong><br></br>
                    Vi bruger postmark til at sende mails ud med, så derved sender vi din mailadresse over til dem når vi sender mails. En kopi af de udsendte emails er gemt hos dem i 41 dage hvorefter de bliver slettet.
                  </p>
                  <br></br>
                  <p>
                    <strong>Mobilepay</strong><br></br>
                    Når et event er færdig, og du opkræves af mobilepay, sender vi dit mobilnummer og navn igennem mobilepay.
                  </p>
                  <br></br>
                  <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Cookies:</h2>
                    <p>Vi sætter kun tekniske cookies i form af:
                    </p>
                    <br></br>
                  <p>
                    <strong>Session cookie</strong><br></br>
                    Den gemmer oplysninger lokalt på din computer om hvilken side du er på, så vi kan opdatere resultaterne på den pågældende side, uden du skal opdatere siden. Derudover bruges det i vores admin panel til at holde styr på at du er logget ind.
                  </p>
                  <br></br>
                  <p>
                    <strong>Google Analytic</strong><br></br>
                    Derudover tracker vi aktivitet på init-together.dk igennem Google Analytic.
                  </p>
                  <br></br>
                  <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Projekter</h2>
                  <p>
                  Visualize-it har ingen indflydelse på hvorledes NGOen forvalter donationerne. Det er en sag mellem løbsafvikler og ngo’en at bestemme hvor donationerne bruges. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }