import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { CursorClickIcon, MailOpenIcon, UsersIcon, CalculatorIcon, CashIcon, ChartBarIcon, OfficeBuildingIcon, ShareIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function renderSwitch(icon) {
    switch(icon) {
        case 'UsersIcon':
            return <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'MailOpenIcon':
            return <MailOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CursorClickIcon':
            return <CursorClickIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CalculatorIcon':
            return <CalculatorIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CashIcon':
            return <CashIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'ChartBarIcon':
            return <ChartBarIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'OfficeBuildingIcon':
            return <OfficeBuildingIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'ShareIcon':
            return <ShareIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      default:
        return '';
    }
  }

export default function EventStats(props) {

    const [ stats, setStatsList ] = useState([]);
    const axiosPrivat = useAxiosPrivate();
    
    const initGetStatsList = async () => {
        let isMounted = true;
        const controller = new AbortController();

        const getStatstList = async () => {
            try {
                const response = await axiosPrivat.get('/stats/'+props.slug, {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setStatsList(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getStatstList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
        initGetStatsList();
        //console.log(stats);
        window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
          if (event.update === 'success') {
            initGetStatsList();
          }
      });
      return () => {
        window.Echo.disconnect()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <div>
        <br></br>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Live statestik</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                  {renderSwitch(item.icon)}                            
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              {!item.hideChanges ?
                <>
                  <p
                    className={classNames(
                      item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold'
                    )}
                  >
                    {item.changeType === 'increase' ? (
                      <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                    ) : (
                      <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                    )}

                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                    {item.change}
                  </p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                    </div>
                  </div>
                </>
              : ''}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}