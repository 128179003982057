import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CreateParticipants from './CreateParticipants';
import CreateSponsor from './CreateSponsor';
import EventParticipantList from './EventParticipantList';
import EventStats from './EventStats';
import UpdateParticipant from './UpdateParticipant';
import UpdateSponsor from './UpdateSponsor';



export default function EventParticipants() {
    const { searchTerm } = useAuth();
    const { event_slug } = useParams()
    const [ participantsList, setParticipantsList ] = useState([])
    const axiosPrivat = useAxiosPrivate()
    const [ popupParticipant, setPopupParticipant ] = useState(false)
    const [ popupSponsor, setPopupSponsor ] = useState(false)
    const [ popupUpdateSponsor, setPopupUpdateSponsor ] = useState(false)
    const [ popupUpdateParticipant, setPopupUpdateParticipant ] = useState(false)
    const [ sponsorIdUpdate, setSponsorIdUpdate ] = useState();
    const [ participantSlugUpdate, setParticipantSlugUpdate ] = useState();
    const [ showStats, setShowStats ] = useState(true);

    const openPopupParticipant = async () => {
        setPopupParticipant(true)
        setTimeout(() => {
            setPopupParticipant(false)
          }, 1000);
    }

    const openPopupSponsor = async () => {
        setPopupSponsor(true)
        setTimeout(() => {
            setPopupSponsor(false)
          }, 1000);
    }

    const openPopupUpdateSponsor = async (sponsorId) => {
        setSponsorIdUpdate(sponsorId)
        setPopupUpdateSponsor(true)
        setTimeout(() => {
            setPopupUpdateSponsor(false)
          }, 1000);
    }

    const openPopupUpdateParticipant = async (participantSlug) => {
        setParticipantSlugUpdate(participantSlug)
        setPopupUpdateParticipant(true)
        setTimeout(() => {
            setPopupUpdateParticipant(false)
          }, 1000);
    }

    const initGetParticipantList = async () => {
        let isMounted = true;
        const controller = new AbortController();

        const getParticipantList = async () => {
            try {
                const response = await axiosPrivat.get('/event/get/'+event_slug+'/participant/sponsor', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setParticipantsList(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getParticipantList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
          if (searchTerm && searchTerm !== '') {
            setShowStats(false);
          } else {
            setShowStats(true);
          }
      }, [searchTerm]);

    useEffect(() => {
        setParticipantsList(participantsList);
        initGetParticipantList();
        window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
            if (event.update === 'success') {
                initGetParticipantList();
                console.log('Event updated');
            }
        });
        return () => {
            window.Echo.disconnect()
        }
    }, [])

  return (
    <>   
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    { showStats ? 
      <EventStats slug={event_slug} />
      : ""}
    </div>
     <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <EventParticipantList title={"Alle deltagere for " + event_slug} participants={participantsList} openPopupParticipant={() => openPopupParticipant()} openPopupSponsor={() => openPopupSponsor()} openPopupUpdateSponsor={(sponsorId) => openPopupUpdateSponsor(sponsorId)} openPopupUpdateParticipant={(participantSlug) => openPopupUpdateParticipant(participantSlug)} />
    </div>
    <CreateParticipants eventSlug={event_slug} show={popupParticipant} />
    <CreateSponsor participantsList={participantsList} eventSlug={event_slug} show={popupSponsor} />
    <UpdateSponsor participantsList={participantsList} show={popupUpdateSponsor} sponsorId={sponsorIdUpdate} />
    <UpdateParticipant participantsList={participantsList} show={popupUpdateParticipant} eventSlug={event_slug} participantSlug={participantSlugUpdate}/>
    </>
  )
}