import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function QrCodeList(props) {
    const axiosPrivat = useAxiosPrivate();
    const [ qrList, setQrList ] = useState([]);
    const [ qrCount, setQrCount ] = useState(1);

    const initGetQrList = async () => {
        let isMounted = true;
        const controller = new AbortController();

        const getQrList = async () => {
            try {
                const response = await axiosPrivat.get('qr/get/all', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setQrList(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getQrList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    const createQrCode = async () => {
      try {
          const response = await axiosPrivat.post('qr/create', {
              count: qrCount
          });
      } catch (err) {
          console.error(err);
      }
    }

    const deleteQeCode = async ( id ) => {
      try {
          const response = await axiosPrivat.delete('qr/delete/'+id);
          initGetQrList();
      } catch (err) {
          console.error(err);
      }
    }

    const generate = async () => {
      createQrCode();
    }

    useEffect(() => {
        initGetQrList();
        setQrCount(1);
        window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
            if (event.status === 'qr_code_created' || event.status === 'qr_code_updated') {
                initGetQrList();
            }
        });
        return () => {
          setQrList([]);
          setQrCount(1);
          window.Echo.disconnect()
        }
    }, [])

    return (
      <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Qr koder</h1>
        </div>

        <div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Antal
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                    type="text"
                    name="event-name"
                    id="event-name"
                    onChange={(e) => setQrCount(e.target.value)}
                    autoComplete="given-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    required
                />
                </div>
            </div>
            <button
            type="button"
            onClick={() => generate()}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            >
            Opret
            </button>
        </div>
       
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    QrCode
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Kode
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Link
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Tilknyttet Event
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Tilknyttet NGO
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white align-middle">
                {qrList.map((qr, qrIdx) => (
                  <tr key={qrIdx} className={qrIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <td className="whitespace-nowrap py-3 pl-4 text-sm text-gray-900">
                      <img src= {"data:image/svg+xml;base64," + qr.base64_qr_code} width={80}></img>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{qr.slug}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <a href={qr.url} className="text-indigo-600 hover:text-indigo-900" target="_blank">
                        {qr.url}
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{qr.event_name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{qr.reserved_for_ngo?.name}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <button
                        type="button"
                        onClick={() => props.openPopup(qr.id)}
                        className="text-indigo-600 hover:text-indigo-900"
                        >
                      Rediger
                      </button>
                      <br></br>
                      <br></br>
                      <button
                        type="button"
                        onClick={() => deleteQeCode(qr.id)}
                        className="text-indigo-600 hover:text-indigo-900"
                        >
                      Slet
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    )
  }