import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ComponentHeadline from '../../utillityComponents/ComponentHeadline';
import CreateUser from './CreateUser';

import List from './List';

export default function UserHonme() {
    const axiosPrivat = useAxiosPrivate();
    const [ popup, setPopup ] = useState(false)
    const [ eventSlugList, setEventSlugList ] = useState([])
    
    const openPopup = async () => {
        setPopup(true)
        setTimeout(() => {
            setPopup(false)
          }, 1000);
    }

    const initGetEventSlugList = async () => {
        let isMounted = true;
        const controller = new AbortController();

        const getEventSlugList = async () => {
            try {
                const response = await axiosPrivat.get('settings/event/all/auth', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setEventSlugList(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getEventSlugList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
        initGetEventSlugList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (
    <>
    <ComponentHeadline icon="UserGroupIcon" pagetitle="Brugere" />
    <List openPopup={() => openPopup()} />
    <CreateUser slugList={eventSlugList} show={popup} />
    </>
  )
}