import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";
import PacmanLoader from "react-spinners/PacmanLoader";

export default function CreateProject(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)


  const [photoFile, setPhotoFile] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [eventId, setEventId] = useState(null);
  const [price, setPrice] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
    }
  }, [props.show])

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });

  const handleChange = (file) => {
    setPhotoFile(file[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    formData.append("img", photoFile);
    formData.append("name", projectName);
    formData.append("description", projectDescription);
    formData.append("event_id", eventId);
    formData.append("sort_order", sortOrder);
    formData.append("price", price);
    
    const saveEdit = async () => {
       try {
            setLoading(true);
            const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/project/create', formData
            );

            isMounted  && setOpen(false)
        } catch (err) {
            setLoading(false);
            console.error(err);
            console.log(photoFile);
        } 
        setLoading(false);
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Opret projekt</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="project-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Projekt navn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="project-name"
                                id="project-name"
                                onChange={(e) => setProjectName(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="project-description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Projekt beskrivelse
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <textarea
                                rows={8}
                                name="project-description"
                                id="project-description"
                                value={projectDescription}
                                onChange={(e) => setProjectDescription(e.target.value)}
                                autoComplete="given-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                              />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="event" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Event/Eventgruppe
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                              id="event"
                              name="event"
                              onChange={(e) => setEventId(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue=""
                            >
                              <option></option>
                              {props.projectList.map((event) => (
                                <option key={event.id} value={event.id}>{event.name}</option>
                              ))}
                          </select>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="price" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Beløb
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="price"
                                id="price"
                                onChange={(e) => setPrice(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="sort-order" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Rækkefølge
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="sort-order"
                                id="sort-order"
                                onChange={(e) => setSortOrder(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                            Billede
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input  
                                name="image"
                                id="image"
                                type="file"
                                onChange={(e) => handleChange(e.target.files)}
                                className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                />
                            </div>
                        </div>

                        </div>
                    </div>

                    
                    
                    </div>
            
                    {!loading ? <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Opret
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    :
                        <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                      }
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}