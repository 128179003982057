import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_BASEURL;
const REACT_APP_API_BASEURL_AUTH = process.env.REACT_APP_API_BASEURL_AUTH;
const REACT_APP_API_BASEUR_PUBLIC = process.env.REACT_APP_API_BASEUR_PUBLIC;


export default axios.create({
    baseURL: BASE_URL
});

export const axiosAuth = axios.create({
    baseURL: REACT_APP_API_BASEURL_AUTH,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('aT'),
    },
    withCredentials: true
});

export const axioPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json'},
    withCredentials: true
});

export const axioPrivateLogin = axios.create({
    baseURL: REACT_APP_API_BASEUR_PUBLIC,
    headers: { 'Content-Type': 'application/json'},
});