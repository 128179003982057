import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UserGroupIcon } from '@heroicons/react/outline'
import axios from "axios";
import IsAuth from '../../utillityComponents/IsAuth';
import { PacmanLoader } from 'react-spinners';

export default function CreateUser(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [eventSlug, setEventSlug] = useState('');
  const [photoFile, setPhotoFile] = useState(null);
  const [ngo, setNgo] = useState(null);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('User');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
    }
    
  }, [props.show])

  useEffect(() => {
    setNgo(null);
    setRole('User');
    setFirstName('');
    setLastName('');
    setEventSlug('');
    setEmail('');
    setPhotoFile(null);
  }, [open])

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });

  const handleChange = (file) => {
    setPhotoFile(file[0])
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    if (photoFile !== null) {
      formData.append("img", photoFile);
    }
    if (role !== null) {
      formData.append("role", role);
    }
    if (ngo !== null && ngo !== 'null') {
      formData.append("ngo_slug", ngo);
    }
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("event_slug", eventSlug);
    formData.append("email", email);

    const saveEdit = async () => {
       try {
            setLoading(true);
            const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/user/create', formData
            );

            isMounted  && setOpen(false) 
            props.masterGetUsers()
        } catch (err) {
            console.error(err);
            console.log(photoFile);
            setLoading(false);
        }  
        setLoading(false);
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <UserGroupIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Opret bruger</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Fornavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) => setFirstName(e.target.value)}
                                autoComplete="given-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>
            
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Efternavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                onChange={(e) => setLastName(e.target.value)}
                                autoComplete="family-name"
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email address
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                                className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Country
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                                id="country"
                                name="country"
                                autoComplete="country-name"
                                className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                                <option>Denmark</option>
                            </select>
                            </div>
                        </div>

                        {IsAuth(['Admin', 'Ngo']) ?
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="eventname" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Event navn
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                              id="eventname"
                              name="eventname"
                              onChange={(e) => setEventSlug(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue=""
                            >
                              <option></option>
                              {props.slugList.map((event) => (
                                <option key={event.id} value={event.slug}>{event.name}</option>
                              ))}
                            </select>
                              </div>
                          </div>
                          : ''
                        }

                        {IsAuth(['Admin', 'Ngo']) ? 
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="role" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Brugergruppe
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                                  id="role"
                                  name="role"
                                  onChange={(e) => setRole(e.target.value)}
                                  autoComplete="role"
                                  defaultValue="User"
                                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                {IsAuth(['Admin']) ? 
                                <>
                                  <option key="Admin" value="Admin">Admin</option>
                                  <option key="Ngo" value="Ngo">Ngo</option>
                                  <option key="Org_admin" value="Org_admin">Org Admin</option>
                                  </>
                                 : "" 
                                 }
                                  <option key="Event_admin" value="Event_admin">Event Admin</option>
                                  <option key="User" selected="selected" value="User">User</option>
                              </select>
                              </div>
                          </div>
                          : ''
                        }

                        {IsAuth(['Admin']) && role === 'Ngo' ? 
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="ngo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Ngo
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                                  id="ngo"
                                  name="ngo"
                                  onChange={(e) => setNgo(e.target.value)}
                                  autoComplete="ngo"
                                  defaultValue="Lovespring"
                                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                              >
                                  <option key="default" selected="selected" value="null"></option>
                                  <option key="Lovespring" value="ls">Lovespring</option>
                              </select>
                              </div>
                          </div>
                          : ''
                        }

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                            Billede
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="flex items-center">
                                <input  
                                name="image"
                                id="image"
                                type="file"
                                onChange={(e) => handleChange(e.target.files)}
                                className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                />
                            </div>
                            </div>
                        </div>
                                                
            
                        </div>
                    </div>

                    
                    
                    </div>
            
                    <>
                    {!loading ? <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Opret
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    : <PacmanLoader
                    color="#26103c"
                    cssOverride={{margin: "20px auto"}}
                    loading
                    margin={0}
                    size={24}
                    speedMultiplier={1}
                    />
                    }
                    </>  

                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}