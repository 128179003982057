export default function Roles() {

  const roles = {
    'admin' : 'Admin',
    'org' : 'Org_admin',
    'event' : 'Event_admin',
    'user' : 'User',
    'ngo' : 'Ngo',
  }
  
  return roles
}