import { Outlet } from "react-router-dom";
import ComponentHeadline from "../../utillityComponents/ComponentHeadline";

export default function CollectHome() {

  return (
    <>
    <ComponentHeadline icon="InboxInIcon" pagetitle="Indberet" />
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <Outlet />
    </div>
    </>
  )
}