import { useState } from "react";
import { Outlet } from "react-router-dom";
import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import HardwareList from "./HardwareList";
import UpdateHardware from "./UpdateHardware";

export default function HardwareHome() {

  const [ popup, setPopup ] = useState(false)
  const [ updateHardware, setUpdateHardware ] = useState('')

  const openPopupUpdateHardware = async (slug) => {
    setUpdateHardware(slug)
    setPopup(true)
    setTimeout(() => {
      setPopup(false)
      }, 1000);
  }

  return (
    <>
    <ComponentHeadline icon="DesktopComputerIcon" pagetitle="Display" />
    <UpdateHardware slug={updateHardware} show={popup} />
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <HardwareList openPopupUpdateHardware={(slug) => openPopupUpdateHardware(slug)} />
    </div>
    </>
  )
}