import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PaymentList from "./PaymentList";
import PaymentStats from "./PaymentStats";

export default function PaymentInit() {
  const axiosPrivat = useAxiosPrivate();
  const { event_slug } = useParams()
  const [ paymentList, setPaymentList ] = useState([])
  const [ showStats, setShowStats ] = useState(true)
  const { searchTerm } = useAuth()

  const initGetPaymentList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getPaymentList = async () => {
        try {
            const response = await axiosPrivat.get('/mobilepay/invoice/'+event_slug, {
                signal: controller.signal
            });
            //console.log(response.data);
            isMounted && setPaymentList(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    getPaymentList();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  useEffect(() => {
      if (searchTerm && searchTerm !== '') {
        setShowStats(false);
      } else {
        setShowStats(true);
      }
  }, [searchTerm]);

  useEffect(() => {
    initGetPaymentList();
    window.Echo.connect()
      window.Echo.channel("invoices").listen(".newInvoice", (event) => {
          if (event.update === 'success') {
            initGetPaymentList();
              console.log('Invoice updated');
          }
      });
}, [])

  return (
    <>
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    { showStats ? 
      <PaymentStats slug={event_slug} />
      : ""}
    </div>
    <PaymentList list={paymentList} event_slug={event_slug}/>
    </>
  )
}