import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import AllStats from "./AllStats";

export default function OverviewHome() {
  return (
    <>
    <ComponentHeadline icon="ChartPieIcon" pagetitle="Overblik" />
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <AllStats />
    </div>
    </>
  )
} 