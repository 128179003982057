import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";
import IsAuth from '../../utillityComponents/IsAuth';

export default function UpdateSponsor(props) {
  const [open, setOpen] = useState(props.show)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [tlf, setTlf] = useState('')
  const [newStartnr, setNewStartnr] = useState(null)
  const [startnr, setStartnr] = useState('')
  const [showSelect, setShowSelect] = useState(false)
  const [donateOnce, setDonateOnce] = useState('')
  const [donateKm, setDonateKm] = useState('')
  const [paymentType, setPaymentType] = useState('')
  const [sponsorId, setSponsorId] = useState('')
  const [paymentFail, setPaymentFail] = useState(false)
  const [confirmPayment, setConfirmPayment] = useState(false)
  const [confirmPaymentFail, setConfirmPaymentFail] = useState(false)
  

  useEffect(() => {
    if (props.sponsorId !== undefined) {
      getSponsor( props.sponsorId )
    }
    if (props.show) {
        setOpen(props.show);
    }
    setPaymentFail(false)
    setConfirmPayment(false)
    setConfirmPaymentFail(false)
    return () => {
      setPaymentFail(false)
      setConfirmPayment(false)
      setConfirmPaymentFail(false)
      setShowSelect(false)
      setNewStartnr(null)
    }
    // eslint-disable-next-line
  }, [props.show])

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'application/json',
    },
  });

  const getSponsor = async ( old_id ) => {
    try {
      const response = await uploadAxios.get(process.env.REACT_APP_API_BASEURL + '/sponsor/get/'+old_id);
      setFirstName(response.data.first_name)
      setLastName(response.data.last_name)
      setEmail(response.data.email)
      setTlf(response.data.phone_number)
      setStartnr(response.data.participant_startnr)
      setDonateOnce(response.data.donation_fixed)
      setDonateKm(response.data.donation_km)
      setPaymentType(response.data.payment_type)
      setSponsorId(response.data.id)
    } catch (err) {
        console.error(err);
    }
    setShowSelect(true)
  }

  const startBilling = async (confirmPayment, sponsorId) => { 
    if (confirmPayment == false) {
      setConfirmPaymentFail(true)
      return
    }
    try {
      const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/sponsor/startBilling/'+sponsorId);
      setOpen(false)
    } catch (err) {
        setPaymentFail(true)
        setConfirmPaymentFail(false)
        console.error(err);
    }
  }

  const handleSubmitPaticipant = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();

    const saveEdit = async () => {
      try {
        await uploadAxios.patch(process.env.REACT_APP_API_BASEURL + '/sponsor/update/'+props.sponsorId, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: tlf,
          participant_startnr: startnr,
          new_start_nr: newStartnr,
          donation_fixed: donateOnce,
          donation_km: donateKm
        });
        isMounted && setOpen(false);
      } catch (err) {
          console.error(err);
      }
    }

    saveEdit()

    return () => {
      isMounted = false;
      controller.abort();
  } 
  }

  const deleteEvent = async ( old_id ) => {
    try {
      await uploadAxios.delete(process.env.REACT_APP_API_BASEURL + '/sponsor/delete/'+old_id);
      console.log('delete event')
      setOpen(false)
    } catch (err) {
        console.error(err);
    }
  }

  return ( 
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmitPaticipant} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Rediger Sponsor</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Fornavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Efternavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="tlf" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Mobilnummer
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="tel"
                                name="tlf"
                                id="tlf"
                                onChange={(e) => setTlf(e.target.value)}
                                value={tlf}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="patricipant" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Deltager
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <>
                              {showSelect ?
                              <select
                              id="participant"
                              name="participant"
                              onChange={(e) => setNewStartnr(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={startnr}
                              >
                              <option key="0" disabled></option>
                              {props.participantsList.map((participant) => (
                                <option key={participant.startnr} value={participant.startnr}>{participant.name}</option>
                              ))}
                              </select>
                              : ""}
                              </>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="donate_km" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Donationer: Kr/km
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="donate_km"
                                id="donate_km"
                                onChange={(e) => setDonateKm(e.target.value)}
                                value={donateKm}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="donate_once" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Donationer: Fast beløb
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="donate_once"
                                id="donate_once"
                                onChange={(e) => setDonateOnce(e.target.value)}
                                value={donateOnce}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        {IsAuth(['Admin']) ? 
                        <>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div className="text-sm">
                            <label htmlFor="PaymentConform" className="font-medium text-gray-700">
                                Bekræft betalings
                            </label>
                            <span id="PaymentConform-description" className="text-gray-500">
                              <span className="sr-only">Bekræft betalings</span>
                            </span>
                          </div>
                          <div className="center center h-5">
                            <input
                              id="PaymentConform"
                              aria-describedby="changeImg-description"
                              name="PaymentConform"
                              type="checkbox"
                              defaultChecked={confirmPayment}
                              onChange={(e) => setConfirmPayment(e.target.checked)}
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>                       
                            { confirmPaymentFail ? <div className="text-red-500 text-sm">Du skal bekræfte betaling for at kunne udsende</div> : "" }
                        </div>
                        </>
                          : ''}
                        {IsAuth(['Admin']) ?
                        <>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label htmlFor="donate_once" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Betalinger
                            </label>
                            { paymentFail ? <p className="text-red-500 text-xs italic">Har allerede modtaget betaling</p> 
                            : 
                            <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                            onClick= {() => {startBilling(confirmPayment, sponsorId)}}
                            >
                            Send { paymentType == 'mobilepay' ? 'Mobilepay' : paymentType == 'transfer' ? 'Overførsels mail' : '' }
                            </button>
                            }
                        </div>
                        </>
                        : ''}

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <button
                            type="button"
                            className="w-50 flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                            onClick= {() => deleteEvent(props.sponsorId)}
                            >
                            Slet Sponsor
                            </button>
                        </div>
                        
                        </div>
                    </div>

                    
                    
                    </div>
            
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Gem
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {setOpen(false)}}
                        >
                        Annuller
                        </button>
                    </div>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}