import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

export default function EventList() {
    const [ eventList, setEventList ] = useState([])
    const axiosPrivat = useAxiosPrivate();

    const initGetEventList = async () => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getEventList = async () => {
            try {
                const response = await axiosPrivat.get('/event/all/org', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setEventList(response.data);
            } catch (err) {
                console.error(err);
            }
        }
    
        getEventList();
    
        return () => {
            isMounted = false;
            controller.abort();
        }
    }
    
    useEffect(() => {
        initGetEventList();
        return () => {
            setEventList([])
        }
    }, [])

  return (
    <>
    <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
            Vælg et event
    </h2>
    <div className="bg-white shadow overflow-hidden sm:rounded-md mt-10">
      <ul role="list" className="divide-y divide-gray-200">
        {eventList.sort((a, b) => a.name > b.name ? 1 : -1).map((application) => (
          <li key={application.id}>
            <Link to={'/indberet/'+application.slug} className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-12 w-12 rounded-full" src={application.img + "/36x36"} alt={application.name}  />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm text-left font-medium text-indigo-600 truncate">{application.name}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span className="truncate">{application.org_name}</span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
  
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                          {moment(application.event_start_date).format("dddd, DD MMM HH:mm YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
    </>
  )
}