import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonStandard from '../../utillityComponents/ButtonStandard';
import IsAuth from "../../utillityComponents/IsAuth";
import StatusStyles from "../../utillityComponents/StatusStyles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const tabs = [
  { index: 1, name: 'Se Alle', slug: 'all'},
  { index: 2, name: 'Se Aktiveret', slug: 'active'},
  { index: 3, name: 'Se Deaktiveret', slug: 'deactive'},
]

export default function List(props) {
  const axiosPrivat = useAxiosPrivate();
  const statusStyles = StatusStyles()
  const [ users, setUsers ] = useState([]);
  const [ slug, setSlug ] = useState();
  

  const masterGetUsers = async ( props ) => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async ( props ) => {
        try {
            var extention = '';

            if (props === 'all') {
              extention = '';
            }

            if (props === 'active') {
              extention = '?active=true';
            }

            if (props === 'deactive') {
              extention = '?active=false';
            }
            const response = await axiosPrivat.get('/user/all' + extention, {
                signal: controller.signal
            });
            //console.log(response.data);
            isMounted && setUsers(response.data);
            localStorage.setItem('user_list-current-page', props);
            setSlug(props);
        } catch (err) {
            console.error(err);
        }
        
    }

    getUsers( props );

    return () => {
        isMounted = false;
        controller.abort();
    }
  }


  useEffect(() => {
    masterGetUsers(localStorage.getItem('user_list-current-page') ? localStorage.getItem('user_list-current-page') : 'active');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUsers = async ( props ) => {
    masterGetUsers(props);
  }

  return (
      <>
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
    {props.title}
  </h2>

    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        <div className="sm:hidden">
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            onChange={(e) => getUsers(e.target.value)}
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          >
            {tabs.map((tab) => (
              <option key={tab.index} value={tab.slug}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.index}
                type="button"
                onClick= {() => getUsers(tab.slug)}
                className={classNames(
                  tab.slug === slug ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                  'rounded-md px-3 py-2 text-sm font-medium'
                )}
                aria-current={tab.slug === slug ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th>
                    </th>
                    <th scope="col" className="font-semibold whitespacepy-4 pl-0 pr-3 text-sm text-gray-900 sm:pl-3">
                      Navn
                    </th>
                    <th scope="col" className="font-semibold whitespacepy-4 pl-0 pr-3 text-sm text-gray-900 sm:pl-3">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Events
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Sidst logget ind
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Aktiveret
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {users.map((role) => (
                    <Fragment key={role.name}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={6}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {role.name}
                        </th>
                      </tr>
                      {role.users.sort((a, b) => a.first_name > b.first_name ? 1 : -1).map((users, eventsIdx) => (
                        <tr
                          key={users.id}
                          className={classNames(eventsIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td
                           className='py-2 block h-9 w-9 rounded-full ml-4 mr-3'
                          >
                          <img
                            className="block h-9 w-9 rounded-full ml-4"
                            src={users.cloudflare_img_uuid ? process.env.REACT_APP_CLUDFLARE_IMG_BASE_URL + users.cloudflare_img_uuid + "/36x36" : process.env.REACT_APP_CLUDFLARE_DEFAULT_IMG_URL + "/36x36"}
                            alt={users.name}
                          />
                          </td>
                          <td className="whitespacepy-4 pl-0 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {users.name}
                          </td>
                          <td className="whitespacepy-4 pl-0 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                            {users.email}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500">
                            <p>
                            {users.events.map((event) => (
                              event.slug + '  '
                            ))
                            }
                            </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{users.last_login ? moment(users.last_login).format("dddd, DD MMM HH:mm YYYY") : 'aldrig'}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span
                            className={classNames(
                              statusStyles[users.is_activated ? 'aktiv' : 'deaktiveret'],
                              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                            )}
                          >
                            {users.is_activated ? 'aktiv' : 'deaktiveret'}
                          </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link to={'/user/edit/' + users.id} className="text-indigo-600 hover:text-indigo-900 float-right">
                                Edit<span className="sr-only">, {users.name}</span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      {IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
        <ButtonStandard text={'Opret bruger'} size="medium" click={props.openPopup} />
        : ''
      }
    </div>
    </div>
    </>
    )
  }
  
