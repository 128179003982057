import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QrcodeIcon } from '@heroicons/react/outline'
import PacmanLoader from "react-spinners/PacmanLoader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function EditQr(props) {
  const axiosPrivat = useAxiosPrivate();
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState("");
  const [event, setEvent] = useState(null);
  const [eventGroup, setEventGroup] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [eventGroupList, setEventGroupList] = useState([]);
  const [ngo, setNgo] = useState(null);
  const [ngoList, setNgoList] = useState([]);
  const [errorText, setErrorText] = useState(null);


  useEffect(() => {
    if (props.show) {
        setSlug("");
        setErrorText(null);
        setEvent(null);
        setEventGroup(null);
        setEventList([]);
        setEventGroupList([]);
        setNgo(null);
        setNgoList([]);
        getQrCode(props.qrId);
        setOpen(props.show);
    }
  }, [props.show])

  const handleSubmit = async (event) => {
    event.preventDefault();
  }

  const getQrCode = async ( id ) => {
    setLoading(true);
    try {
        const response = await axiosPrivat.get('qr/get/' + id);
        const qrCode = response.data;
        setSlug(qrCode.slug);
        setEventList(qrCode.event_list);
        setEventGroupList(qrCode.event_group_list);
        qrCode.event_group_list.map((event_group_list) => (event_group_list.id === qrCode.event_id ? setEventGroup(qrCode.event_id) : ""))
        qrCode.event_list.map((event_list) => (event_list.id === qrCode.event_id ? setEvent(qrCode.event_id) : ""))
        setNgo(qrCode.reserved_for_ngo_id);
        setNgoList(qrCode.ngo_list);
    } catch (err) {
        console.error(err);
    }
    setLoading(false);
  }

  const updateQrCode = async (id) => {
    setErrorText(null);
    if (event !== null && eventGroup !== null) {
        setErrorText("Der kan ikke være både et event og en event gruppe tilknyttet en QR-kode");
        return
    }
    setLoading(true);
    try {
      const response = await axiosPrivat.patch('qr/update/' + id, {
          event_id: event,
          event_group_id: eventGroup,
          reserved_for_ngo_id: ngo
      });
    } catch (err) {
        console.error(err);
        setLoading(false);
        return
    }
    setLoading(false);
    setOpen(false)
  }

  useEffect(() => {
    if (open === false) {
        setSlug("");
        setErrorText(null);
        setEvent(null);
        setEventGroup(null);
        setEventList([]);
        setEventGroupList([]);
        setNgo(null);
        setNgoList([]);
    }
      return () => {
        setSlug("");
        setErrorText(null);
        setEvent(null);
        setEventGroup(null);
        setEventList([]);
        setEventGroupList([]);
        setNgo(null);
        setNgoList([]);
      }
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <QrcodeIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Qr kode: {slug}</h3>
                        </div>
            
                        {loading ? "" :
                        <>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="event" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Tilknyt event
                              </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <select
                                id="event"
                                name="event"
                                onChange={(e) => setEvent(e.target.value)}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={event}
                              >
                                <option value="">Ingen</option>
                                {eventList.map((events, eventIdx) => (
                                  <option key={eventIdx} value={events.id}>{events.name}</option>
                                ))}
                            </select>
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="event" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Tilknyt eventgruppe
                              </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <select
                                id="event"
                                name="event"
                                onChange={(e) => setEventGroup(e.target.value)}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={eventGroup}
                              >
                                 <option value="">Ingen</option>
                                {eventGroupList.map((events, eventIdx) => (
                                  <option key={eventIdx} value={events.id}>{events.name}</option>
                                ))}
                            </select>
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="event" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Tilknyt Ngo
                              </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <select
                                id="event"
                                name="event"
                                onChange={(e) => setNgo(e.target.value)}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={ngo}
                              >
                                 <option value="">Ingen</option>
                                {ngoList.map((ngos, eventIdx) => (
                                  <option key={eventIdx} value={ngos.id}>{ngos.name}</option>
                                ))}
                            </select>
                              </div>
                          </div>





                        </>
                        }

                        {errorText === null ? "" : <p className="mt-2 text-sm text-red-600" id="email-error">{errorText}</p>}
                    </div>

                    
                    
                    </div>
                     <>
                    {!loading ? <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="button"
                        onClick={() => updateQrCode( props.qrId )}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Opret
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    : <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                    }
                    </>  
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}