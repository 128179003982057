import { useState } from "react";
import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import QrCodeList from "./QrCodeList";
import EditQr from "./EditQr";

export default function QrHome() {
  const [ popup, setPopup ] = useState(false)
  const [ qrId, setQrId ] = useState(null)

  const openPopup = async ( id ) => {
    setQrId(id)
    setPopup(true)
    setTimeout(() => {
        setPopup(false)
      }, 1000);
  }

  return (
    <>
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <QrCodeList openPopup={( id ) => openPopup( id )} />
    </div>

    <EditQr qrId={qrId} show={popup}/>
    </>
  )
}