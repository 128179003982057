import moment from "moment";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useParams} from "react-router-dom";
export default function DistanceDetailsList() {
  const axiosPrivat = useAxiosPrivate();
  const [ list, setList ] = useState([])
  const { event_slug } = useParams()
  const initGetDistanceDetailList = async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getDistanceDetailsList = async () => {
      try {
        const response = await axiosPrivat.get('/distance/get/'+event_slug+'/details', {
          signal: controller.signal
        });
        //console.log(response.data);
        isMounted && setList(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    getDistanceDetailsList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  useEffect(() => {
    initGetDistanceDetailList();
    window.Echo.connect()
    window.Echo.channel("events").listen(".newEvent", (event) => {
      if (event.update === 'success') {
        initGetDistanceDetailList();
      }
    });
  }, [])

  return (
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                      Event runde
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                      Deltager
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      løberens runde
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Rute
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Minutter siden sidste runde
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Indberettet
                    </th>

                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    </th>               
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {list.map((rounds, personIdx) => (
                    <tr key={rounds.total_round_number} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {rounds.total_round_number}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rounds.participant_name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rounds.round_number}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rounds.lable}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rounds.lap_time_in_minutes} Minutter</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(rounds.created_at).format("dddd, DD MMM HH:mm YYYY")}</td>
                      <td className="max-w-[40px] px-3 py-4 text-sm text-gray-500">
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}