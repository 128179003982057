import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonStandard from '../../utillityComponents/ButtonStandard';
import IsAuth from "../../utillityComponents/IsAuth";
import StatusStyles from "../../utillityComponents/StatusStyles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Combobox, Dialog, Transition } from '@headlessui/react';


const people = [
  { id: 1, name: 'Leslie Alexander', url: '#' },
  // More people...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HistorikList() {
    const axiosPrivat = useAxiosPrivate();
    const [ historik, setHistorik ] = useState([]);
    const [ from, setFrom ] = useState(0);
    const [ to, setTo ] = useState(0);
    const [ next, setNext ] = useState(0);
    const [ before, setBefore ] = useState(0);
    const [ lastPage, setLastPage ] = useState(0);
    const [ changeLength, setChangeLength ] = useState(["0"]);
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [ people, setPeople ] = useState([]);
    const [ userId, setUserId ] = useState(undefined);


    const filteredPeople =
    query === ''
      ? []
      : people.filter((person) => {
          return person.user_display_name.toLowerCase().includes(query.toLowerCase())
        })

    
    const masterGetHistorik = async ( props, id ) => {
        let isMounted = true;
        const controller = new AbortController();

        const geHistorik = async ( props, id ) => {
            try {

                let page = '?page=0';
                let user = '';
                if ( props !== undefined ) {
                  page = '?page='+props; 
                }
                if ( id !== undefined ) {
                  user = '&user='+id;
                }
                const response = await axiosPrivat.get('/user/change_history'+page+user, {
                    signal: controller.signal
                });
                
                isMounted && setHistorik(response.data.changes.data)
                setPeople(response.data.users)
                setFrom(response.data.changes.from)
                setTo(response.data.changes.to)
                setNext(response.data.changes.current_page ? response.data.changes.current_page + 1 : 0)
                setBefore(response.data.changes.current_page ? response.data.changes.current_page - 1 : 0)
                setLastPage(response.data.changes.last_page)
                setChangeLength(response.data.changes.total)
                setOpen(false)
                setUserId(id)
            } catch (err) {
                console.error(err);
            }
            
        }

        geHistorik( props, id );

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
        masterGetHistorik();
    }, [])
  
  return (
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
      <ButtonStandard text={'Filter'} size="medium" click={() => setOpen(true)} />

      <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={(person) => (masterGetHistorik('', person.id))}>
                  <div className="relative">
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </div>

                  {filteredPeople.length > 0 && (
                    <Combobox.Options static className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                      {filteredPeople.map((person) => (
                        <Combobox.Option
                          key={person.id}
                          value={person}
                          className={({ active }) =>
                            classNames('cursor-default select-none px-4 py-2', active && 'bg-indigo-600 text-white')
                          }
                        >
                          {person.user_display_name}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}

                  {query !== '' && filteredPeople.length === 0 && (
                    <p className="p-4 text-sm text-gray-500">No people found.</p>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6">
                      Bruger
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Tabel
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Kolonne
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Gamle værdi
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Nye værdi
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                      Tidspunkt
                    </th>                 
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {historik.map((historik, personIdx) => (
                    <tr key={personIdx} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {historik.user?.user_display_name ?? ''}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.change_type}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.table_name}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.column_name}</td>
                      <td className="max-w-[40px] px-3 py-4 text-sm text-gray-500">{historik.old_value}</td>
                      <td className="max-w-[40px] px-3 py-4 text-sm text-gray-500">{historik.new_value}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{historik.created_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700">
                            Viser <span className="font-medium">{from}</span> til <span className="font-medium">{to}</span> ud af{' '}
                            <span className="font-medium">{changeLength}</span> resultater
                          </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <button
                            type="button"
                            onClick= {() => masterGetHistorik(before, userId)}
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Tilbage
                          </button>
                          <button
                            type="button"
                            onClick= {() => masterGetHistorik(next, userId)}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Næste
                          </button>
                        </div>
                      </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
  }
  
