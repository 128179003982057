import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AllEventList from './AllEventList';
import CreateEvent from './CreateEvent';
import CreateOrginasation from './CreateOrginasation';
import UpdateEvent from './UpdateEvent';
import UpdateRoute from './UpdateRoute';



export default function AllEvents() {
    const [ eventList, setEventList ] = useState([]);
    const [ updateEvent, setUpdateEvent ] = useState();
    const axiosPrivat = useAxiosPrivate();
    const [ popup, setPopup ] = useState(false)
    const [ popuporg, setPopupOrg ] = useState(false)
    const [ popupUpdateEvent, setPopupUpdateEvent ] = useState(false)
    const [ popupUpdateRoute, setPopupUpdateRoute ] = useState(false)

    const openPopup = async () => {
        setPopup(true)
        setTimeout(() => {
            setPopup(false)
          }, 1000);
    }

    const openPopupOrg = async () => {
        setPopupOrg(true)
        setTimeout(() => {
            setPopupOrg(false)
          }, 1000);
    }

    const openPopupUpdateEvent = async (name) => {
        setUpdateEvent(name)
        setPopupUpdateEvent(true)
        setTimeout(() => {
            setPopupUpdateEvent(false)
          }, 1000);
    }

    const openPopupUpdateRoute = async (name) => {
        setUpdateEvent(name)
        setPopupUpdateRoute(true)
        setTimeout(() => {
            setPopupUpdateRoute(false)
          }, 1000);
    }
    
    const initGetEventList = async () => {
        let isMounted = true;
        const controller = new AbortController();

        const getEventList = async () => {
            try {
                const response = await axiosPrivat.get('settings/org/all/auth', {
                    signal: controller.signal
                });
                //console.log(response.data);
                isMounted && setEventList(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getEventList();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
      setEventList(eventList);
        initGetEventList();
        window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
            if (event.update === 'success') {
                initGetEventList();
                //console.log('Event updated');
            }
        });
        return () => {
            window.Echo.disconnect()
        }
    }, [])


  return (
    <>   
     <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <AllEventList events={eventList} title="Events" openPopup={() => openPopup()} openPopupOrg={() => openPopupOrg()} openPopupUpdateEvent={(name) => openPopupUpdateEvent(name)} openPopupUpdateRoute={(name) => openPopupUpdateRoute(name)}/>
    </div>
    
    <CreateEvent events={eventList} show={popup} />
    <UpdateEvent events={eventList} old_event={updateEvent} show={popupUpdateEvent} />
    <UpdateRoute old_event={updateEvent} show={popupUpdateRoute} />
    <CreateOrginasation events={eventList} show={popuporg} initGetEventList={() => initGetEventList()} />
    </>
  )
}