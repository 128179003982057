import { Fragment, useEffect, useState } from 'react'
import { Outlet, NavLink, useNavigate, Link } from 'react-router-dom'
import logo from '../assets/img/init-together-logo-white.svg'
import useAuth from '../hooks/useAuth'
import useLogout from '../hooks/useLogout'

import { Dialog, Menu, Transition } from '@headlessui/react'

import {
  CogIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  CalendarIcon,
  RefreshIcon,
  XIcon,
  MapIcon,
  DesktopComputerIcon,
  QrcodeIcon,
  // eslint-disable-next-line
  CashIcon,
  CollectionIcon,
  // eslint-disable-next-line
  GlobeAltIcon,
} from '@heroicons/react/outline'
import {
  ChartPieIcon,
  ChevronDownIcon,
  // eslint-disable-next-line
  InboxInIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import useRefreshUserData from '../hooks/useRefreshUserData'


const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon, role: ['Admin', 'Org_admin', 'Event_admin', 'Ngo', 'User']},
  { name: 'Brugere', href: '/user', icon: UserGroupIcon, role: ['Admin', 'Ngo', 'Org_admin', 'Event_admin']},
  { name: 'Events', href: '/events', icon: CalendarIcon, role: ['Admin', 'Org_admin', 'Event_admin', 'Ngo']},
  { name: 'Indberet', href: '/indberet', icon: InboxInIcon, role: ['Admin', 'Org_admin', 'Event_admin', 'Ngo', 'User']},
  /* { name: 'Organisation', href: '/organisation', icon: GlobeAltIcon, role: ['Admin']}, */
  { name: 'Projekter', href: '/projekter', icon: CollectionIcon, role: ['Admin', 'Ngo']},
  { name: 'Overblik', href: '/overblik', icon: ChartPieIcon, role: ['Admin']},
  { name: 'Betalinger', href: '/betalinger', icon: CashIcon, role: ['Admin', 'Ngo']},
  { name: 'Display', href: '/hardware', icon: DesktopComputerIcon, role: ['Admin', 'Ngo']},
  { name: 'Qr Koder', href: '/qrcode', icon: QrcodeIcon, role: ['Admin']},
  { name: 'Historik', href: '/historik', icon: GlobeAltIcon, role: ['Admin']},
]
const secondaryNavigation = [
  { name: 'Indstillinger', href: '/user/edit', icon: CogIcon, role: ['Admin', 'Org_admin', 'Ngo', 'Event_admin'] },
  { name: 'FAQ', href: '/faq', icon: QuestionMarkCircleIcon, role: ['Admin', 'Org_admin', 'Ngo', 'Event_admin'] },
  { name: 'Privatoplysninger', href: '/terms', icon: ShieldCheckIcon, role: ['Admin', 'Org_admin', 'Event_admin', 'Ngo', 'User'] },
  { name: 'Interne Systemer', href: '/system-list', icon: MapIcon, role: ['Admin'] },
]

const redirectLinks = [
  { name: 'Release Notes', href: 'https://visualize-it.onset.io/releases', blank: true, icon: RefreshIcon, role: ['Admin', 'Ngo', 'Org_admin', 'Event_admin'] },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MasterLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const refreshUserData = useRefreshUserData();
  const { auth, userData, searchTerm, setSearchTerm } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();
  const signOut = async () => {
    await logout();
    navigate('/')
  }

  useEffect(() => {
    refreshUserData();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="min-h-screen bg-white">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-purple">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="Init-together logo"
                  />
                </div>
                <nav
                  className="mt-5 flex-shrink-0 h-full divide-y divide-cyan-800 overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="px-2 space-y-1">
                    {navigation.map((item) => (
                      auth?.roles?.find(role => item.role?.includes(role)) ?
                      <NavLink
                      end
                        key={item.name}
                        to={item.href}
                        className={ ({ isActive }) => classNames(
                          isActive ? 'bg-cyan-800 text-white' : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                        aria-current={ ({ isActive }) => (isActive ? 'page' : undefined)}
                      >
                        <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
                        {item.name}
                      </NavLink>
                      : ''
                    )                    
                    )}
                  </div>
                  <div className="mt-6 pt-6">
                    <div className="px-2 space-y-1">
                      {secondaryNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                        >
                          <item.icon className="mr-4 h-6 w-6 text-cyan-200" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </nav>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 bg-primary-purple lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-8 w-auto"
                src={logo}
                alt="Init-together logo"
              /> <h3 className='ml-2 text-white text-base font-medium'>Kontrolpanel</h3>
            </div>
            <nav className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
              <div className="px-2 space-y-1">
                {navigation.map((item) => (
                  auth?.roles?.find(role => item.role?.includes(role)) ?
                  <NavLink
                    end
                    key={item.name}
                    to={item.href}
                    className={ ({ isActive }) =>  classNames(
                      isActive ? 'bg-cyan-800 text-white' : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                      'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                    )}
                    aria-current={({ isActive }) => (isActive ? 'page' : undefined)}
                  >
                    <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                  : ''
                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    auth?.roles?.find(role => item.role?.includes(role)) ?
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600"
                    >
                      <item.icon className="mr-4 h-6 w-6 text-cyan-200" aria-hidden="true" />
                      {item.name}
                    </NavLink>
                    : ''
                  ))}
                  {redirectLinks.map((item) => (
                    auth?.roles?.find(role => item.role?.includes(role)) ?
                    <a href={item.href} target='_blank' className='group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-cyan-100 hover:text-white hover:bg-cyan-600'> 
                    <item.icon className="mr-4 h-6 w-6 text-cyan-200" aria-hidden="true" />
                    {item.name} </a>
                    : ''
                  ))}
                </div>
              </div>
            </nav>
          </div>
          <div className="flex-shrink-0 text-white p-4">
              <Link to="/" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={userData.profil_img + "/36x36"}
                      alt={userData.name}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{userData.name}</p>
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200"><button onClick={signOut}>Log ud</button></p>
                  </div>
                </div>
              </Link>
            </div>
        </div>

        <div className="lg:pl-64 flex flex-col flex-1">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-primary-purple text-white border-b border-gray-200 lg:border-none">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Åben sidebar</span>
              <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Søg
                  </label>
                  <div className="relative w-full text-white-400 focus-within:text-white-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none" aria-hidden="true">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      name="search-field"
                      onChange={(e) => setSearchTerm(e.target.value)} 
                      value={searchTerm}
                      className="block w-full h-full pl-8 pr-3 py-2 bg-primary-purple border-transparent text-white-100 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Søg"
                      type="search"
                    />
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  <span className="sr-only">Vis notifikationer</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs rounded-full flex items-center text-sm focus:ring-cyan-500 lg:p-2 lg:rounded-md">
                      <img
                        className="h-8 w-8 rounded-full"
                        src={userData.profil_img + "/36x36"}
                        alt={userData.name}
                      />
                      <span className="hidden ml-3 text-white-700 text-sm font-medium lg:block">
                        <span className="sr-only">Åben brugerprofil for </span>{userData.name}
                      </span>
                      <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            end
                            to="/"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Din profil
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink
                            end
                            to="/user/edit"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Indstillinger
                          </NavLink>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          // eslint-disable-next-line
                          <a className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}><button onClick={signOut}>Log ud</button></a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="flex-1 pb-8">
            {/* Page header */}
            

            <div className="mt-8">
            <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default MasterLayout;
