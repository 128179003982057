import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { CursorClickIcon, MailOpenIcon, UsersIcon, CalculatorIcon, CashIcon, ChartBarIcon, OfficeBuildingIcon, ShareIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function renderSwitch(icon) {
    switch(icon) {
        case 'UsersIcon':
            return <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'MailOpenIcon':
            return <MailOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CursorClickIcon':
            return <CursorClickIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CalculatorIcon':
            return <CalculatorIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'CashIcon':
            return <CashIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'ChartBarIcon':
            return <ChartBarIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'OfficeBuildingIcon':
            return <OfficeBuildingIcon className="h-6 w-6 text-white" aria-hidden="true" />;
        case 'ShareIcon':
            return <ShareIcon className="h-6 w-6 text-white" aria-hidden="true" />;
      default:
        return '';
    }
  }

const tabs = [
  { index: 1, name: 'Altid', slug: 'always', props: '2020-01-01'},
  { index: 2, name: '2021', slug: '2021', props: '2021-01-01:2022-01-01'},
  { index: 3, name: '2022', slug: '2022', props: '2022-01-01:2023-01-01'},
  { index: 4, name: '2023', slug: '2023', props: '2023-01-01:2024-01-01'},
  { index: 4, name: '2024', slug: '2024', props: '2024-01-01:2025-01-01'},
  { index: 5, name: 'Upcoming', slug: 'future', props: new Date().toISOString().slice(0, 10)},
  { index: 6, name: 'Nye platform', slug: 'new_platform', props: '2022-01-01'},
]

export default function AllStats() {
    const [ currentPeriod, setCurrentPeriod ] = useState();
    const [ periodHeadline, setPeriodHeadline ] = useState();
    const [ stats, setStatsList ] = useState([]);
    const axiosPrivat = useAxiosPrivate();
    
    
    const initGetStatsList = async ( fromDate ) => {
        let isMounted = true;
        const controller = new AbortController();

        const getStatstList = async ( fromDate ) => {
          var from = '';
          if (fromDate !== undefined) {
            from = '?from=' + fromDate;
          }
            try {
                const response = await axiosPrivat.get('/stats/all' + from, {
                    signal: controller.signal,
                });
                //console.log(response.data);
                isMounted && setStatsList(response.data);
                if (fromDate !== undefined) {
                  localStorage.setItem('all_stats-current-page', fromDate);
                }
            } catch (err) {
                console.error(err);
            }
        }

        getStatstList(fromDate);

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
      initGetStatsList(currentPeriod);
      tabs.map((item) => {
        if (item.props === currentPeriod) {
          setPeriodHeadline(item.name);
        }
        return true
      })
      // eslint-disable-next-line
    }, [currentPeriod])

    const setBtnPeriod = async ( props ) => {
      tabs.map((item) => {
        if (item.slug === props) {
          setCurrentPeriod(item.props);
        }
        return true
      })
    }

    useEffect(() => {
        setStatsList(stats);
        setCurrentPeriod(localStorage.getItem('all_stats-current-page') ? localStorage.getItem('all_stats-current-page') : '2023-01-01:2024-01-01');
        //console.log(stats);
        window.Echo.connect()
        window.Echo.channel("events").listen(".newEvent", (event) => {
          if (event.update === 'success') {
            initGetStatsList(currentPeriod);
          }
      });
      return () => {
        window.Echo.disconnect()
      }
      // eslint-disable-next-line
    }, [])

  return (
    <>
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Vælg en periode
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => setBtnPeriod(e.target.value)}
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.index} value={tab.slug}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.index}
              type="button"
              onClick= {() => setBtnPeriod(tab.slug)}
              className={classNames(
                tab.name === periodHeadline ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={tab.name === periodHeadline ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  
    <div>
        <br></br>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Events: {periodHeadline}</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                  {renderSwitch(item.icon)}                            
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              {!item.hideChanges ?
                <>
                  <p
                    className={classNames(
                      item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold'
                    )}
                  >
                    {item.changeType === 'increase' ? (
                      <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                    ) : (
                      <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                    )}

                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                    {item.change}
                  </p>
                  <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                    </div>
                  </div>
                </>
                : ''}

            </dd>
          </div>
        ))}
      </dl>
    </div>
    </>
  )
}