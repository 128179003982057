import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import IsAuth from '../../utillityComponents/IsAuth';
import PacmanLoader from "react-spinners/PacmanLoader";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function UpdateRoute(props) {
  const [open, setOpen] = useState(props.show)
  const axiosPrivat = useAxiosPrivate();
  const cancelButtonRef = useRef(null)

  const [eventSlug, setEventSlug] = useState('');
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isReady, setIsReady] = useState(false);

    const [routeName, setRouteName] = useState("");
    const [routeLength, setRouteLength] = useState("");


  
  useEffect(() => {
    setIsReady(false);
    setRoutes([])
    setEventSlug(props.old_event)
    if (props.old_event !== undefined) {
      getRoute()
    }
    if (props.show) {
        setOpen(props.show);
    }
    // eslint-disable-next-line
  }, [props.show])

  const getRoute = async ( ) => {
    setLoading(true);
    try {
      const response = await axiosPrivat.get(process.env.REACT_APP_API_BASEURL + "/event/get/"+eventSlug+"/routes");
      setRoutes(response.data.map(route => ({
          ...route,
          tempLabel: route.Lable,
          tempLength: route.route_on_meters
      })));
        setRouteName("");
        setRouteLength("");
      setIsReady(true);
    } catch (err) {
        console.error(err);
    }
    setLoading(false);
  }



const createRoute = async ( ) => {
    setLoading(true);

    const formData = new FormData();

    if (routeName !== null) {
        formData.append("name", routeName);
    }

    if (routeLength !== null) {
        formData.append("route_on_meters", routeLength);
    }

    if (routeLength === null || routeName === null || routeLength === "" || routeName === "") {
        setLoading(false);
        return;
    }

    try {
        const response = await axiosPrivat.post(process.env.REACT_APP_API_BASEURL + "/event/get/"+eventSlug+"/routes", formData);
        await getRoute();
    } catch (err) {
        console.error(err);
    }
    setLoading(false);
}


const updateRoute = async ( route ) => {
    setLoading(true);

    try {
        const response = await axiosPrivat.patch(process.env.REACT_APP_API_BASEURL + "/event/get/"+eventSlug+"/routes", {
            id: route.id,
            name: route.tempLabel,
            route_on_meters: route.tempLength,
        });
        await getRoute();
    } catch (err) {
        console.error(err);
    }
    setLoading(false);
}


  const handleSubmit = async (e) => {
    e.preventDefault();

  }

    const handleInputChange = (id, field, value) => {
        setRoutes(current => current.map(route => route.id === id ? { ...route, [field]: value } : route));
    };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Ændre ruter for - {eventSlug}</h3>
                            <p>Distance er i meter og der kan ikke bruges tegn i tallene (så hverken . eller ,)</p>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">

                        {isReady ? routes.map((route) => (
                                
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                  <input
                                      type="text"
                                      value={route.tempLabel}
                                      onChange={(e) => handleInputChange(route.id, 'tempLabel', e.target.value)}
                                      className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                  />
                                  <input
                                      type="number"
                                      value={route.tempLength}
                                      onChange={(e) => handleInputChange(route.id, 'tempLength', e.target.value)}
                                      className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                  />
                                  <button
                                      type="button"
                                      onClick={() => updateRoute(route)}
                                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                      Gem
                                  </button>
                              </div>
                          </div>
                        )) : ""}

                            <div
                                className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="text"
                                        name={"new_route_name"}
                                        id={"new_route_name"}
                                        value={routeName}
                                        onChange={(e) => setRouteName(e.target.value)}
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        required
                                        placeholder={"Navn på rute"}
                                    />
                                    <input
                                        type="number"
                                        name={"new_route"}
                                        id={"new_route"}
                                        value={routeLength}
                                        onChange={(e) => setRouteLength(e.target.value)}
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                        required
                                        placeholder={"Rute i meter"}
                                    />
                                    <button
                                        type="submit"
                                        onClick={() => createRoute()}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                    >
                                        Opret ny rute
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    
                    
                    </div>
                    <>
                    {!loading ?
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {setOpen(false)}}
                        ref={cancelButtonRef}
                        >
                        Luk
                        </button>
                    </div>
                    : <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                    }
                    </>   
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}