import { Fragment, useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth';
import ButtonStandard from '../../utillityComponents/ButtonStandard'
import IsAuth from '../../utillityComponents/IsAuth'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function EventParticipantList(props) {
  const { searchTerm } = useAuth();
  const [ activeList, setActiveList ] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm && searchTerm !== '' && searchTerm.length > 1) {
        let searchTermSrape = searchTerm.toLowerCase()
        const filteredItems = props.participants.filter(participants => {
            return (
            participants.name.toLowerCase().includes(searchTermSrape) ||
            participants.startnr.toLowerCase().includes(searchTermSrape)
            )});   
        setActiveList(filteredItems);
      } else {
        setActiveList(props.participants);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [searchTerm, props.participants]);
  
  return (
      <>
    <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
    {props.title}
  </h2>
    <div className="px-4 sm:px-6 lg:px-8">
    <br></br>
      {IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
              <ButtonStandard text={'Tilføj Deltager'} size="medium" click={props.openPopupParticipant} />
              : ''
            }
       {IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
              <ButtonStandard text={'Tilføj Sponsor'} size="medium" click={props.openPopupSponsor} />
              : ''
      }
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="py-3.5 pl-0 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                      Navn
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      kr/km
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Fast Donation
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Total kr/km
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Total Donationer
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Betalingtype
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Rediger</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {activeList.sort((a, b) => a.startnr > b.startnr ? 1 : -1).map((participant) => (
                    <Fragment key={participant.name}>
                      <tr
                      key={participant.id} 
                      className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                           <img
                            className="inline-block h-9 w-9 rounded-full ml-4 mr-5"
                            src={participant.img + "/36x36"}
                            alt={participant.name}
                          />
                          {participant.startnr + ' - ' + participant.name}  --  {new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(participant.donation_km)} kr/km   --  {new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(participant.total_donation_fixed)} kr/fast
                        </th>
                        <th colSpan={1} scope="colgroup" className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"></th>
                        <th colSpan={2} scope="colgroup" className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">
                        {IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
                          <button
                            key={participant.id + "_edit"}
                              type="button"
                              className= "text-indigo-600 hover:text-indigo-900 float-right"
                              onClick= {() => props.openPopupUpdateParticipant(participant.slug)}
                            >
                              Edit
                            </button>
                            : ""                       
                          }
                        </th>
                      </tr>
                      {participant.sponsor.map((sponsor, eventsIdx) => (
                        <tr
                          key={sponsor.id}
                          className={classNames(eventsIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td className="whitespace-nowrap py-4 pl-0 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {sponsor.first_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(sponsor.donation_km)} kr</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(sponsor.donation_fixed)} kr</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(sponsor.total_donation_km)} kr</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Intl.NumberFormat('da-DK', {maximumFractionDigits: 0}).format(sponsor.total_donation)} kr</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{sponsor.payment_type == 'mobilepay' ? 'Mobilepay' : sponsor.payment_type == 'transfer' ? 'Overførsel' : sponsor.payment_type == 'credit_card' ? 'Kreditkort' : ''}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            
                            <button
                            key={sponsor.id + "_edit"}
                              type="button"
                              className= "text-indigo-600 hover:text-indigo-900"
                              onClick= {() => props.openPopupUpdateSponsor(sponsor.id)}
                            >
                              Edit
                            </button>                            
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}