import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from '../../assets/img/Logo-whit.png';

import { axioPrivateLogin } from '../../api/axios';
import { LockClosedIcon } from '@heroicons/react/outline';

const ForgotPassword = () => {
    const userRef = useRef();
    const errRef = useRef();
    const successRef = useRef();

    const [email, setEmail] = useState(null);
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setEmail(null);
        setErrMsg('')
        setSuccessMsg('')
        setSuccess(false)
    }, [])

    useEffect(() => {
        setErrMsg('')
        setSuccessMsg('')
        setSuccess(false)
    }, [email])

    const resetHandleSubmit = async (e) => {
        e.preventDefault();
        setErrMsg('')
        setSuccessMsg('')

        try {
            const response = await axioPrivateLogin.post('/login/forgot/',
                JSON.stringify({email: email}),
            );
            setSuccess(true)
            setSuccessMsg('Der er nu sendt en email til dig med et link til at nulstille din kode. Linket er gyldigt i 10 minuter.')
        } catch (err) {
            setErrMsg('Der opstod en fejl, prøv igen eller kontakt din administrator')
        }
    }

    return (
      <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src={logo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white-900">
            Init-Together Admin Dashboard
          </h2>
          <p className="mt-2 text-center text-sm">
            <a href="#" className="font-medium  text-white-600 hover:text-primary-blue">
              Nulstil kode
            </a>
          </p>
        </div>
        <form onSubmit={resetHandleSubmit} className="mt-8 space-y-6 bg-white p-10 rounded-xl shadow-gray-500 shadow-md">    
         <h2 className='text-black' >Indtast email - så sender vi en ny kode til din mail, hvis du findes i vores system</h2>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                ref={userRef}
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email adresse"
              />
            </div>
            <h3 ref={successRef} className={successMsg ? "errmsg text-black pt-5 text-center" : "offscreen"} >{successMsg}</h3>
            <h3 ref={errRef} className={errMsg ? "errmsg text-red-500 pt-5 text-center" : "offscreen"} >{errMsg}</h3>
          </div>

          { success ? 
           <div className="text-sm">
           <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
             Tilbage til login
           </a>
          </div>
          :
          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
              </span>
              Send Kode
            </button>
          </div>
          }
          
        </form>
      </div>
    </div>
    </>
    )
  }

  export default ForgotPassword;