import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate";


const useRefreshUserData = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth, setUserData } = useAuth();

    const refreshUserData = async () => {
        let isMounted = true;
        const controller = new AbortController();
 
        try {
            const response = await axiosPrivate.get('/user/get/' + auth.id, {
                signal: controller.signal
            });
            //console.log(JSON.stringify(response?.data));
            const roles = response?.data?.roles;
            const name = response?.data?.user_display_name;
            const id = response?.data?.id;
            const email = response?.data?.user_email;
            const profil_img = response?.data?.user_profile_image ? process.env.REACT_APP_CLUDFLARE_IMG_BASE_URL + response?.data?.user_profile_image : process.env.REACT_APP_CLUDFLARE_DEFAULT_IMG_URL + "/36x36";
            const first_name = response?.data?.user_first_name;
            const last_name = response?.data?.user_last_name;
            const city = response?.data?.user_city;
            isMounted && setUserData({ profil_img, email, id, roles, name, first_name, last_name, city })
        } catch (err) {
           //console.log(err?.response)
        }               

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

  return (refreshUserData)
}

export default useRefreshUserData