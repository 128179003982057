import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import IsAuth from "../../utillityComponents/IsAuth";


export default function ParticipantList( props ) {
  const { searchTerm } = useAuth();
  const [ activeList, setActiveList ] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm && searchTerm !== '' && searchTerm.length > 0) {
        let searchTermSrape = searchTerm.toLowerCase()
        const filteredItems = props.list.filter(list => {
            return (
            list.name.toLowerCase().includes(searchTermSrape) ||
            list.startnr.toString().includes(searchTermSrape)
            )});   
        setActiveList(filteredItems);
      } else {
        setActiveList(props.list);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [searchTerm, props.list]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="mt-2 text-xl font-semibold text-gray-900">Deltagere</h1>
          <p className="mt-2 text-sm text-gray-700">
            Indberet en runde for er deltager.
          </p>
        </div>
      </div>
      <div className="mt-4 sm:mt-0 text-left sm:flex-none">
          <button
          type="button"
          className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => props.openRegisterBulkPopup()}
          >
          Indberet Startnummer
          </button> 

          {IsAuth(['Admin', 'Ngo', 'Org_admin', 'Event_admin']) ?
              <>
                  <Link
                    to={`/indberet/${props.event_slug}/details`}
                    className="inline-flex float-right items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Se detaljer
                  </Link>

                  <Link
                      to={`/indberet/${props.event_slug}/distance`}
                      className="mr-10 inline-flex float-right items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                      Se indberetninger
                  </Link>
              </>

              : ''}

        </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {activeList
                    .sort((a, b) => a.startnr > b.startnr ? 1 : -1)
                    .map((participant) => (
                    <tr key={participant.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-9 w-9 rounded-full" src={participant.img+"/36x36"} alt={participant.name} />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{participant.startnr} - {participant.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">{participant.total_rounds} runder <br></br> {participant.total_meters/1000} km </div>
                      </td>
                      {participant.routes.map((route, eventsIdx) => (

                          <td key={eventsIdx} className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              key={participant.id + "_edit"}
                              type="button"
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => props.openRegisterPopup(participant.name, participant.startnr, participant.event_slug, route.id, route.Lable, route.route_on_meters)}
                              >
                              {route.Lable}
                            </button>
                          </td>
                  
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}