import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

export default function RegisterBulk(props) {
  const [open, setOpen] = useState(props.show)
  const [ startnr, setStartnr ] = useState(null)
  const [ error, setError ] = useState('')
  const [ distanceList, setDistanceList ] = useState([])
  const axiosPrivat = useAxiosPrivate();
  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
        setStartnr(null)
    } 
    // eslint-disable-next-line
  }, [props.show])

  useEffect(() => {
    initDistanceList()
    // eslint-disable-next-line
  }, [props.show])

  useEffect(() => {
    setError('')
    // eslint-disable-next-line
  }, [startnr])
  
  const handleSubmit = async (id) => {
    try {
      const response = await axiosPrivat.post('/event/register/'+props.event_slug+'/routes', {
          route_id: id,
          startnr: startnr
      });
      setOpen(false)
    } catch (err) {
        console.error(err);
        setError('Der skete ej fejl. Ret start nummer og prøv igen')
    }
  }

  const initDistanceList = async (e) => {
    let isMounted = true;
    const controller = new AbortController();

    const getDistanceList = async () => {
        try {
            const response = await axiosPrivat.get('/event/register/'+props.event_slug+'/routes', {
                signal: controller.signal
            });
            //console.log(response.data);
            isMounted && setDistanceList(response.data);
        } catch (err) {
            console.error(err);
        }
    }

    getDistanceList();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg text-center leading-6 ">Registrer omgang på startnummer</h3>
                        <h3 className="text-lg text-center leading-6 text-red-600">{error}</h3>
                        
                        <div className="sm:grid w-[300px] m-auto text-center sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="rounds" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Startnumm: 
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              id="rounds"
                              name="rounds"
                              type="number"
                              onChange={(e) => setStartnr(e.target.value)}
                              required
                              className="relative block w-[100px] appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="space-y-6 sm:space-y-5">

                        </div>
                    </div>

                    
                    
                    </div>
                    <>
                    <div className="mt-5 sm:mt-6 text-center pt-5 sm:grid-flow-row-dense">
                    {distanceList.map((routes) => (
                      <>
                        <button
                        type="button"
                        className="rounded-md border mr-5 ml-5 border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => handleSubmit(routes.id)}
                        >
                        {routes.Lable}
                        </button>
                        </>
                    ))} 
                      </div>
                      </>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}