import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'

import PacmanLoader from "react-spinners/PacmanLoader";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import IsAuth from '../../utillityComponents/IsAuth';

export default function UpdateHardware(props) {
  const axiosPrivat = useAxiosPrivate();

  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [displayHeadline, setDisplayHeadline] = useState('');
  const [eventSlug, setEventSlug] = useState('');
  const [line2, setLine2] = useState('');
  const [line3, setLine3] = useState('');
  const [line4, setLine4] = useState('');
  const [color1, setColor1] = useState('');
  const [color2, setColor2] = useState('');
  const [color3, setColor3] = useState('');
  const [color4, setColor4] = useState('');
  const [lines, setLines] = useState('');
  const [font1, setFont1] = useState('');
  const [font2, setFont2] = useState('');
  const [font3, setFont3] = useState('');
  const [font4, setFont4] = useState('');
  const [events, setEvents] = useState([]);


  
  useEffect(() => {
    setError(false);
    if (props.slug !== undefined && props.slug !== null && props.slug !== "") {
      initGetDisplaySettings()
    }
    if (props.show) {
        setOpen(props.show);
        setDisplayName('');
        setDisplayHeadline('');
        setColor1('');
        setColor2('');
        setColor3('');
        setColor4('');
        setFont1('');
        setFont2('');
        setFont3('');
        setFont4('');
        setLine2('');
        setLine3('');
        setLine4('');
        setLines('');
    }
    // eslint-disable-next-line
  }, [props.show])


  const initGetDisplaySettings = async () => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();

    const getDisplaySettings = async () => {
        try {
            const response = await axiosPrivat.get('/hardware/get/' + props.slug, {
                signal: controller.signal
            });
            setDisplayName(response.data.name);
            setDisplayHeadline(response.data.title_line_one);
            setEventSlug(response.data.event_slug);
            setLine2(response.data.title_line_two);
            setLine3(response.data.title_line_three);
            setLine4(response.data.title_line_four);
            setColor1(response.data.color_line_one);
            setColor2(response.data.color_line_two);
            setColor3(response.data.color_line_three);
            setColor4(response.data.color_line_four);
            setFont1(response.data.font_line_one);
            setFont2(response.data.font_line_two);
            setFont3(response.data.font_line_three);
            setFont4(response.data.font_line_four);
            setLines(response.data.lines);
            setEvents(response.data.events);
            setError(false);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setError(true);
        }
    }

    getDisplaySettings();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isMounted = true;
    const controller = new AbortController();

    const updateDisplaySettings = async () => {
        try {
            const response = await axiosPrivat.put('/hardware/get/' + props.slug, {
                signal: controller.signal,
                event: eventSlug,
                line_one: displayHeadline,
                line_two: line2,
                line_three: line3,
                line_four: line4,
                color_line_one: color1,
                color_line_two: color2,
                color_line_three: color3,
                color_line_four: color4,
                font_line_one: font1,
                font_line_two: font2,
                font_line_three: font3,
                font_line_four: font4,
                lines: lines
            });
            setOpen(false);
            setError(false);
        } catch (err) {
            console.error(err);
            setError(true);
        }
    }

    updateDisplaySettings();

    return () => {
        isMounted = false;
        controller.abort();
    }
  
  }

  useEffect(() => {
    return () => {
        setDisplayName('');
        setDisplayHeadline('');
        setColor1('');
        setColor2('');
        setColor3('');
        setColor4('');
        setFont1('');
        setFont2('');
        setFont3('');
        setFont4('');
        setLine2('');
        setLine3('');
        setLine4('');
        setLines('');
        setError(false);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Rediger - {displayName}</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="headline" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Display Overskrift <br></br> (Øverste linie på skærmen) <br></br> (max 17 tegn)
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="headline"
                                id="headline"
                                maxlength="17"
                                onChange={(e) => setDisplayHeadline(e.target.value)}
                                value={displayHeadline}
                                
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="event_slug" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Event <br></br>
                            (Løb der skal vises resultater fra)
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <>
                              {!loading ? 
                              <select
                              id="event_slug"
                              name="event_slug"
                              onChange={(e) => setEventSlug(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue={eventSlug}
                              >
                              <option key="0" disabled></option>
                              {events.map((event) => (
                                <option key={event.id} value={event.slug}>{event.slug}</option>
                              ))}
                              </select>

                              : ""}
                             </>
                            </div>
                        </div>

                        {IsAuth(['Admin']) ?
                        <>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="lines" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Antal linier vist på skærmen
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="number"
                                  name="lines"
                                  id="lines"
                                  onChange={(e) => setLines(e.target.value)}
                                  value={lines}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>
                            
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="color_line1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 1 - Farve 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="color_line1"
                                  id="color_line1"
                                  onChange={(e) => setColor1(e.target.value)}
                                  value={color1}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="font_line1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 1 - Fontsize 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="font_line1"
                                  id="font_line1"
                                  onChange={(e) => setFont1(e.target.value)}
                                  value={font1}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="line2" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 2 - Default tekst 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="line2"
                                  id="line2"
                                  onChange={(e) => setLine2(e.target.value)}
                                  value={line2}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="color_line2" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 2 - Farve 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="color_line2"
                                  id="color_line2"
                                  onChange={(e) => setColor2(e.target.value)}
                                  value={color2}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="font_line2" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 2 - Fontsize 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="number"
                                  name="font_line2"
                                  id="font_line2"
                                  onChange={(e) => setFont2(e.target.value)}
                                  value={font2}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="line3" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 3 - Default tekst 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="line3"
                                  id="line3"
                                  onChange={(e) => setLine3(e.target.value)}
                                  value={line3}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="color_line3" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 3 - Farve 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="color_line3"
                                  id="color_line3"
                                  onChange={(e) => setColor3(e.target.value)}
                                  value={color3}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="font_line3" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 3 - Fontsize 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="number"
                                  name="font_line3"
                                  id="font_line3"
                                  onChange={(e) => setFont3(e.target.value)}
                                  value={font3}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="line4" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 4 - Default tekst 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="line4"
                                  id="line4"
                                  onChange={(e) => setLine4(e.target.value)}
                                  value={line4}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="color_line4" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 4 - Farve 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="color_line4"
                                  id="color_line4"
                                  onChange={(e) => setColor4(e.target.value)}
                                  value={color4}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="font_line4" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Linie 4 - Fontsize 
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                  type="text"
                                  name="font_line4"
                                  id="font_line4"
                                  onChange={(e) => setFont4(e.target.value)}
                                  value={font4}
                                  
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  required
                              />
                              </div>
                          </div>
                        </>
                        : ''}


                        
                        </div>
                    </div>

                    
                    
                    </div>
                    <>
                    {!loading ?
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Gem
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => {setOpen(false)}}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    : <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                    }
                    {
                      error ? <div className="text-red-500 text-sm">Der er sket en fejl. prøv igen eller kontakt info@init-together.dk</div> : null
                    }
                    </>   
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}