import { ChevronRightIcon } from "@heroicons/react/outline";
import StatusStyles from "../../utillityComponents/StatusStyles";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

export default function ListPagination(props) {
  const statusStyles = StatusStyles()
  const [ loginHistory, setLoginHistory ] = useState([]);
  const [ loginHistoryLength, setLoginHistoryLength ] = useState(["0"]);
  const [ from, setFrom ] = useState(0);
  const [ to, setTo ] = useState(0);
  const [ next, setNext ] = useState(0);
  const [ before, setBefore ] = useState(0);
  const [ lastPage, setLastPage ] = useState(0);


  const axiosPrivat = useAxiosPrivate();
    
    const initGetLoginHistory = async ( page ) => {
        let isMounted = true;
        const controller = new AbortController();
      
        const getLoginHistory = async ( page ) => {
  
            try {
              var pagination = '?page=' + localStorage.getItem('login_history-current-page');
              
              if (page !== undefined && page > 0) {
                if (page >= lastPage) {
                  pagination = '?page=' + lastPage;
                } else {
                  pagination = '?page=' + page;
                }
              }

                const response = await axiosPrivat.get('/user/login_history' + pagination, {
                    signal: controller.signal
                });
                //console.log(response.data.data);
                isMounted && setLoginHistory(response.data.data);
                setLoginHistoryLength(response.data.total);
                setFrom(response.data.from);
                setTo(response.data.to);
                setNext(response.data.current_page ? response.data.current_page + 1 : 0);
                setBefore(response.data.current_page ? response.data.current_page - 1 : 0);
                setLastPage(response.data.last_page);
                localStorage.setItem('login_history-current-page', response.data.current_page)
            } catch (err) {
                console.error(err);
            }
        }

        getLoginHistory( parseInt(page) );

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    useEffect(() => {
        initGetLoginHistory();
        // eslint-disable-next-line
    }, [])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
    return (
      <>

      <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                {props.title}
              </h2>

              {/* Activity list (smallest breakpoint only) */}
              <div className="shadow sm:hidden">
                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                  {loginHistory.map((transaction) => (
                    <li key={transaction.ai_id}>
                      <a href={"https://www.iplocation.net/ip-lookup/" + transaction.login_ip} className="block px-4 py-4 bg-white hover:bg-gray-50">
                        <span className="flex items-center space-x-4">
                          <span className="flex-1 flex space-x-2 truncate">
                            <span className="flex flex-col text-gray-500 text-sm truncate">
                              <span className="truncate">{transaction.login_ip}</span>
                              <span className="truncate">{transaction.user_agent}</span>
                              <span>
                                <span className="text-gray-900 font-medium">{transaction.expires}</span>
                              </span>
                              <time dateTime={transaction.login}>{transaction.login}</time>
                            </span>
                          </span>
                          <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>

                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                  aria-label="Pagination"
                >
                  <div className="flex-1 flex justify-between">
                    <button
                      type="button"
                      onClick= {() => initGetLoginHistory(before)}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      Tilbage
                    </button>
                    <button
                      type="button"
                      onClick= {() => initGetLoginHistory(next)}
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                    >
                      button
                    </button>
                  </div>
                </nav>
              </div>

              {/* Activity table (small breakpoint and up) */}
              <div className="hidden sm:block">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex flex-col mt-2">
                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              IP
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              User Agent
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Udløber
                            </th>
                            <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                              Status
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Login dato
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {loginHistory.map((transaction) => (
                            <tr key={transaction.ai_id} className="bg-white">
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="flex">
                                  <a href={"https://www.iplocation.net/ip-lookup/" + transaction.login_ip} rel="noreferrer" target="_blank" className="group inline-flex space-x-2 truncate text-sm">
                                    <p className="text-gray-500 truncate group-hover:text-gray-900">
                                      {transaction.login_ip}
                                    </p>
                                  </a>
                                </div>
                              </td>
                              <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="flex">     
                                  <p className="text-gray-500 truncate group-hover:text-gray-900">
                                      {transaction.user_agent}
                                    </p>
                                </div>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <span className="text-gray-900 font-medium">{transaction.expires} </span>
                              </td>
                              <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                                <span
                                  className={classNames(
                                    statusStyles[transaction.revoked],
                                    'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                  )}
                                >
                                  {transaction.revoked}
                                </span>
                              </td>
                              <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <time dateTime={transaction.login}>{transaction.login}</time>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* Pagination */}
                      <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700">
                            Viser <span className="font-medium">{from}</span> til <span className="font-medium">{to}</span> ud af{' '}
                            <span className="font-medium">{loginHistoryLength}</span> resultater
                          </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <button
                            type="button"
                            onClick= {() => initGetLoginHistory(before)}
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Tilbage
                          </button>
                          <button
                            type="button"
                            onClick= {() => initGetLoginHistory(next)}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Næste
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
      </>
    )
  }
  