import ComponentHeadline from '../../utillityComponents/ComponentHeadline';
import ProfileForm from './ProfileForm';

export default function UserEdit() {
  return (
    <>
    <ComponentHeadline icon="CogIcon" pagetitle="Rediger profil" />'
    <ProfileForm />
    </>
  )
}