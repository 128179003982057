import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import moment from 'moment';
import IsAuth from "../../utillityComponents/IsAuth";
import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const accepted = "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800";
const created = "inline-flex rounded-full px-2 text-xs font-semibold leading-5 text-black-800";
const invalid = "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800";
const paid = "inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800";
const rejected = "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800";
const expired = "inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800";
const canceled = "inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800";

export default function PaymentList( props ) {
  const { searchTerm } = useAuth();
  const [ activeList, setActiveList ] = useState([]);
  const axiosPrivat = useAxiosPrivate();
  const [ eventSlug, setEventSlug ] = useState(props.event_slug);
  const [ type, setType ] = useState('mobilepay');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm && searchTerm !== '' && searchTerm.length > 0) {
        let searchTermSrape = searchTerm.toLowerCase()
        const filteredItems = props.list.filter(list => {
            return (
              list.mobilepay_payment_status.toLowerCase().includes(searchTermSrape) ||
              list.mobilepay_submit_status.toLowerCase().includes(searchTermSrape) ||
              list.mobilepay_comment.toString().includes(searchTermSrape) ||
              list.sponsor_id.toString().includes(searchTermSrape) ||
              list.mobilepay_total_amount.toString().includes(searchTermSrape) 
            )});   
        setActiveList(filteredItems);
      } else {
        setActiveList(props.list);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [searchTerm, props.list]);

  useEffect(() => {
    setEventSlug(props.event_slug)
    return () => setEventSlug(null);
  }, []);

  const canclePayment = async (incoice_id) => {
    try {
      const response = await uploadAxios.delete(process.env.REACT_APP_API_BASEURL + '/sponsor/cancelBilling/'+incoice_id);
    } catch (err) {
        console.error(err);
    }
  };

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'application/json',
    },
  });

  const changeToStripe = async ( eventSlug ) => {
    try {
      const response = await axiosPrivat.post('/mobilepay/invoice/'+eventSlug, {
          stripe: true
      });
      setActiveList(response.data);
      setType('stripe')
    } catch (err) {
        console.error(err);
    }
  }

  const changeToMobilepay = async ( eventSlug ) => {
    try {
      const response = await axiosPrivat.post('/mobilepay/invoice/'+eventSlug);
      setActiveList(response.data);
      setType('mobilepay')
    } catch (err) {
        console.error(err);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="mt-2 text-xl font-semibold text-gray-900">Betalings status</h1>
          
          { type === 'mobilepay' ?
            <>
            <p className="mt-2 text-sm text-gray-700">
            mobilepay anmodninger.
            </p>
            </> : ''
          }

          { type === 'stripe' ?
            <>
            <p className="mt-2 text-sm text-gray-700">
            stripe anmodninger.
            </p>
            </> : ''
          }
         
        </div>
      </div>
      <div className="mt-4 sm:mt-0 text-left sm:flex-none">
        { type === 'mobilepay' ? 
          <>
          <button
            type="button"
            className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => changeToStripe(eventSlug)}
            >
            Skift til stripe
            </button> 
          </> : ''
        }
        { type === 'stripe' ? 
          <>
          <button
            type="button"
            className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => changeToMobilepay(eventSlug)}
            >
            Skift til mobilepay
            </button> 
          </> : ''
        }
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Navn
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Updateret
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Besked/(fejlbesked)
                    </th>
                    {IsAuth(['Admin']) ?
                    <>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tlf
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    </>
                    : ''}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Beløb
                    </th>
                    {IsAuth(['Admin']) ?
                    <>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      
                    </th>  
                    </>
                    : ''}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {activeList
                    .map((invoice) => (
                    <tr key={invoice.id}>
                      <td className="whitespace py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="font-medium text-left text-gray-900">{invoice.name}</div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{moment(invoice.updated_at).format("dddd, DD MMM HH:mm YYYY")}</div>
                      </td>
                      <td className="whitespacepx-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{invoice.mobilepay_error_msg ? invoice.mobilepay_error_msg : invoice.mobilepay_comment}</div>
                      </td>
                      {IsAuth(['Admin']) ?
                    <>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">{invoice.phone_number}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className={
                          invoice.mobilepay_payment_status == "created" ? created: 
                          invoice.mobilepay_payment_status == "accepted" ? accepted :
                          invoice.mobilepay_payment_status == "invalid" ? invalid :
                          invoice.mobilepay_payment_status == "paid" ? paid :
                          invoice.mobilepay_payment_status == "rejected" ? rejected : 
                          invoice.mobilepay_payment_status == "expired" ? expired :
                          invoice.mobilepay_payment_status == "CREATED" ? created :
                          invoice.mobilepay_payment_status == "ABORTED" ? rejected :
                          invoice.mobilepay_payment_status == "EXPIRED" ? expired :
                          invoice.mobilepay_payment_status == "CANCELLED" ? canceled :
                          invoice.mobilepay_payment_status == "CAPTURED" ? paid :
                          invoice.mobilepay_payment_status == "REFUNDED" ? rejected :
                          invoice.mobilepay_payment_status == "AUTHORIZED" ? accepted :
                          invoice.mobilepay_payment_status == "TERMINATED" ? rejected : canceled}>
                        {invoice.mobilepay_payment_status == null ? "" : invoice.mobilepay_payment_status.toLowerCase()}
                        </span>
                      </td>
                      </>
                      : ''
                      }
                    
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invoice.mobilepay_total_amount + ".00 kr"}</td>
                      {IsAuth(['Admin']) ?
                      <>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        { (invoice.mobilepay_payment_status !== "accepted" &&
                            invoice.mobilepay_payment_status !== "invalid" &&
                            invoice.mobilepay_payment_status !== "paid" &&
                            invoice.mobilepay_payment_status !== "rejected" &&
                            invoice.mobilepay_payment_status !== "expired" &&
                            invoice.mobilepay_payment_status !== "ABORTED" &&
                            invoice.mobilepay_payment_status !== "EXPIRED" &&
                            invoice.mobilepay_payment_status !== "CANCELLED" &&
                            invoice.mobilepay_payment_status !== "REFUNDED" &&
                            invoice.mobilepay_payment_status !== "TERMINATED" &&
                            invoice.mobilepay_payment_status !== "CAPTURED" &&
                            invoice.mobilepay_payment_status !== "canceled") ?
                        <button
                          type="button"
                          className="text-indigo-600 hover:text-indigo-900 float-right"
                          onClick= {() => canclePayment(invoice.id)}
                          >
                          Anuller
                        </button>
                        : ''}
                      </td>
                      </>
                      : ''}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}