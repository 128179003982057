import { Link, useNavigate, } from "react-router-dom"

import logo from '../assets/img/init-together-logo.svg'

export default function Unauthorized() {
  let navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault()
    navigate(-1)
    console.log('hej')
  } 
    return (
      <>
      <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <img
                    className="h-48 w-auto"
                    src={logo}
                    alt="Init-together logo"
                  />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-primary-blue uppercase tracking-wide">403 forbidden</p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Ingen adgang!</h1>
              <p className="mt-2 text-base text-gray-500">Beklager, du har ikke rettighedern til at tilgå denne side. </p>
              <p className="mt-2 text-base text-gray-500">Kontakt din administrator.</p>
              <div className="mt-6">
                <a href="" onClick={goBack} className="text-base font-medium text-primary-blue hover:text-indigo-500">
                  Gå tilbage<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Kontakt support
            </Link>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Server status
            </Link>
            <span className="inline-block border-l border-gray-300" aria-hidden="true" />
            <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-600">
              Facebook
            </Link>
          </nav>
        </footer>
      </div>
    </>
    )
  }