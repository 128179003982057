import ComponentHeadline from "../../utillityComponents/ComponentHeadline";
import Systems from "./Systems";

export default function PrivateSystems() {
  return (
    <>
    <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-0">
    <Systems />
    </div>
    </>
  )
}