import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";

const notificationMethods = [
  { id: 'mobilepay', title: 'Mobilepay', value: 'mobilepay' },
  { id: 'transfer', title: 'Bankoverførsel', value: 'transfer' },
  { id: 'credit_card', title: 'Stripe', value: 'credit_card' },
]

const locationMethods = [
  { id: 'dansk', title: 'Dansk', value: 'da' },
  { id: 'engelsk', title: 'Engelsk', value: 'en' },
]

export default function CreateSponsor(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)


  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [donationFixed, setDonationFixed] = useState();
  const [donationKm, setDonationKm] = useState();
  const [email, setEmail] = useState();
  const [tlf, setTlf] = useState();
  const [type, setType] = useState();
  const [location, setLocation] = useState();
  const [participant, setParticipant] = useState(null);

  useEffect(() => {
    if (props.show) {
        setOpen(props.show);
    }
  }, [props.show])

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("donation_fixed", donationFixed);
    formData.append("donation_km", donationKm);
    formData.append("phone_number", tlf);
    formData.append("payment_type", type);
    formData.append("locale", location);
    formData.append("participant_slug", participant);

    if (participant === null) {
      console.error('no participant choosen');
      return
    }
    
    const saveEdit = async () => {
       try {
            const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/event/participant/'+ participant +'/sponsor/create', formData);
            isMounted  && setOpen(false)
        } catch (err) {
            console.error(err);
        }  
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Opret Sponsor</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="participant" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Vælg deltager
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                              id="participant"
                              name="participant"
                              onChange={(e) => setParticipant(e.target.value)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue=""
                            >
                              <option></option>
                              {props.participantsList.map((participantsList) => (
                                <option key={participantsList.id} value={participantsList.slug}>{participantsList.startnr} - {participantsList.name}</option>
                              ))}
                          </select>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Fornavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={(e) => setFirstName(e.target.value)}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Efternavn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                onChange={(e) => setLastName(e.target.value)}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="donation_km" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Donation: kr. pr/km
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="donation_km"
                                id="donation_km"
                                onChange={(e) => setDonationKm(e.target.value)}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="donation_fixed" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Fast donation
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="number"
                                name="donation_fixed"
                                id="donation_fixed"
                                onChange={(e) => setDonationFixed(e.target.value)}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Email
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="tlf" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Mobilnummer
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="tel"
                                name="tlf"
                                id="tlf"
                                pattern="[0-9]{8}"
                                onChange={(e) => setTlf(e.target.value)}
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                required
                            />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Type
                            </label>
                            <fieldset className="mt-4">
                              <div className="0">
                                {notificationMethods.map((notificationMethod) => (
                                  <div key={notificationMethod.id} className="flex items-center">
                                    <input
                                      id={notificationMethod.id}
                                      name="notification-method"
                                      type="radio"
                                      value={notificationMethod.value}
                                      onChange={(e) => setType(e.target.value)}
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                    <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                                      {notificationMethod.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Sprog
                            </label>
                            <fieldset className="mt-4">
                              <div className="0">
                                {locationMethods.map((locationMethod) => (
                                  <div key={locationMethod.id} className="flex items-center">
                                    <input
                                      id={locationMethod.id}
                                      name="location-method"
                                      type="radio"
                                      value={locationMethod.value}
                                      onChange={(e) => setLocation(e.target.value)}
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                    <label htmlFor={locationMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                                      {locationMethod.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                        </div>

                        </div>
                    </div>

                    
                    
                    </div>
            
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Opret
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}