import {
  BellIcon,
  CogIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  CalendarIcon,
  XIcon,
  CashIcon,
  CollectionIcon,
  GlobeAltIcon,
  DesktopComputerIcon,
  CheckCircleIcon, 
  OfficeBuildingIcon
} from '@heroicons/react/outline'
import {
  ChartPieIcon,
  ChevronDownIcon,
  InboxInIcon,
  SearchIcon,
} from '@heroicons/react/solid'   

export default function ComponentHeadline(props) {

  var icon = <InboxInIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />

  switch(props.icon) {
    case "InboxInIcon":
      icon = <InboxInIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "BellIcon":
      icon = <BellIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "CogIcon":
      icon = <CogIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "HomeIcon":
      icon = <HomeIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "MenuAlt1Icon":
      icon = <MenuAlt1Icon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "QuestionMarkCircleIcon":
      icon = <QuestionMarkCircleIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "ShieldCheckIcon":
      icon = <ShieldCheckIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "UserGroupIcon":
      icon = <UserGroupIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "XIcon":
      icon = <XIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "CalendarIcon":
      icon = <CalendarIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "CashIcon":
      icon = <CashIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "CollectionIcon":
      icon = <CollectionIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "GlobeAltIcon":
      icon = <GlobeAltIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "CheckCircleIcon":
      icon = <CheckCircleIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "DesktopComputerIcon":
      icon = <DesktopComputerIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "OfficeBuildingIcon":
      icon = <OfficeBuildingIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "ChartPieIcon":
      icon = <ChartPieIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "ChevronDownIcon":
      icon = <ChevronDownIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;
    case "SearchIcon":
      icon = <SearchIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
    break;

    default:
      icon = <InboxInIcon className="mr-4 h-8 w-8 inline" aria-hidden="true" />
  }


    return (
      <>  
<div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                          {icon}
                           {props.pagetitle}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </>
    )
  }
  