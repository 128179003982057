const people = [
  { name: 'Kuma - Dashboard', type: 'Overvågning', beskrivelse: 'Obervågnings dashboard af alle systemer.', whitelist: 'nej', login:'nej', link: 'kuma.inittogether.dk/status/overblik' },
  { name: 'Kuma - Admin', type: 'Overvågning', beskrivelse: 'Opret overvågninger.', whitelist: 'ja', login:'ja', link: 'kuma.inittogether.dk' },
  { name: 'Cockpit', type: 'Server administration', beskrivelse: 'Systemadgang til alle server.', whitelist: 'ja', login:'ja', link: 'cockpit.inittogether.dk' },
  { name: 'Oneset', type: 'Release Notes', beskrivelse: 'Alle release notes.', whitelist: 'nej', login:'nej', link: 'visualize-it.onset.io/releases' },
  { name: 'Oneset - Admin', type: 'Release Notes', beskrivelse: 'Opret release notes.', whitelist: 'nej', login:'ja', link: 'app.onset.io/visualize-it' },
  { name: 'Envoyer', type: 'Deployment line', beskrivelse: 'Deploy alle laravel backends.', whitelist: 'nej', login:'ja', link: 'envoyer.io/' },
  { name: 'Jira', type: 'Planning', beskrivelse: 'Overblik over opgaver.', whitelist: 'nej', login:'ja', link: 'visualize-it.atlassian.net/jira/software/projects/SKOLE/boards/1' },
  { name: 'Trello', type: 'Planning', beskrivelse: 'Overblik over opgaver med lovespring.', whitelist: 'nej', login:'ja', link: 'trello.com/b/pJQmLgyX/init-together-features' },
  { name: 'Postmark', type: 'Emailservice', beskrivelse: 'Email service samt email templates.', whitelist: 'nej', login:'ja', link: 'postmarkapp.com' },
  { name: 'Backblaze', type: 'Backup', beskrivelse: 'Backup af alle databaser.', whitelist: 'nej', login:'ja', link: 'backblaze.com' },
  { name: 'Hetzner', type: 'Server', beskrivelse: 'Datacenter hvor vores fysiske servere er placeret.', whitelist: 'nej', login:'ja', link: 'hetzner.com' },
  { name: 'Cloudflare', type: 'CDN', beskrivelse: 'Alle domæner findes på cloudflare.', whitelist: 'nej', login:'ja', link: 'cloudflare.com' },
  { name: 'Docs.init-together', type: 'Dokumentation', beskrivelse: 'Dokumentation over alle vores API endpoints.', whitelist: 'nej', login:'nej', link: 'docs.init-together.dk' },
  { name: 'Jumpcloud', type: 'Server', beskrivelse: 'Administrer alle SSH keys på server.', whitelist: 'nej', login:'ja', link: 'console.jumpcloud.com' },
  
]

export default function Systems() {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Systemer vi bruger</h1>
        </div>
       
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-3">
                    System
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Beskrivelse
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Kræver login
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                    Kræver whitelisted IP
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {people.map((person, personIdx) => (
                  <tr key={person.email} className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                      {person.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.type}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.beskrivelse}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.login}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.whitelist}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <a href={"https://"+person.link} className="text-indigo-600 hover:text-indigo-900" target="_blank">
                        Gå til siden<span className="sr-only">, {person.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    )
  }