import useAuth from "./useAuth";
import { axiosAuth } from '../api/axios';

const useLogout = () => {
    const { setAuth, userData, setUserData } = useAuth();

    const logout = async () => {
        
        try {
            localStorage.removeItem('aT');
            localStorage.removeItem('us-Id');
            setAuth({});
            setUserData({});
            const respone = await axiosAuth.post('/auth/sign-out', {
                withCredentials: true,
                email: userData?.email
            });            
        } catch (err) {
            console.error(err);
        }
    } 

    return logout;
}

export default useLogout;